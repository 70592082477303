import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
// import { SocketserviceService } from 'src/app/services/socketservice.service';

@Component({
  selector: 'app-sign-in-souscriptor',
  templateUrl: './sign-in-souscriptor.component.html',
  styleUrls: ['./sign-in-souscriptor.component.scss', "../../../../assets/css/font-awesome.min.css"]
})
export class SignInSouscriptorComponent implements OnInit {
  private titleKey: string = "login-page";
  private subtitleKey: string = "login-page-desc";

  loginForm: FormGroup;
  submitted = false;
  baseurl: string;
  loading = false;
  private userdata: any = '';

  constructor(private title: Title,
              private fb: FormBuilder,
              private resourceService: ResourceService,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService
              // private socketservice: SocketserviceService
  ) { this.title.setTitle(this.getTitle()); }

  ngOnInit() {
    this.createForm();
    this.baseurl = environment.baseUrl;
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      password: [null, Validators.required]
    });
  }

  // "/^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/"
  // "/^(?:\d{10}|\w+@\w+\.\w{2,3})$/"


  get f() {
    return this.loginForm.controls;
  }

  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else { control.markAsTouched(); }
    });
  }

  // submitForm() {
  //   // for (let v in this.login_form.controls) {
  //   //   this.login_form.controls[v].markAsTouched();
  //   // }
  //   this.markFormTouched(this.loginForm);
  //   if (this.loginForm.valid) {
  //     // You will get form value if your form is valid
  //     var formValues = this.loginForm.getRawValue;
  //
  //   } else {
  //     this.loginForm.controls['terms'].setValue(false);
  //   }
  // };

  onSubmit() {
    this.submitted = true;

    this.markFormTouched(this.loginForm);

    if (this.loginForm.invalid) {
      // this.loginForm.controls['terms'].setValue(false);
      this.submitted = false;
      return;
    }
    // const formValues: any = this.loginForm.getRawValue;
    const datas = {
      password: sha256(this.loginForm.value['password']),
      username: this.loginForm.value['email']
    };

    // Trying to connect souscriptor
    this.loading = true;

    // check status souscriptor account
    this.resourceService.getResources_unsafe('/souscriptor/checkstatuts/' + datas.username).subscribe((data) => {
		let response: any;
		response = data;
		if (response === 'Done') {
			this.resourceService.getsaveResource('/souscriptor/connect', datas).subscribe((data: any) => {
				  let user: any = [];
				  user = data;
				  this.loading = false;
				  if (user === 'not connected') {
					this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
				  } else if (!user) {
					this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
				  } else {
					// Setting token access and session variables souscriptor
					localStorage.setItem('token', user.access_token);
					sessionStorage.setItem('checksess', 'e-Fid@mlm0@SouS');
					user = user.user;
					this.resourceService.sendData(user);
					sessionStorage.setItem('isConnect', 'true');
					sessionStorage.setItem('userId', user.id);
					sessionStorage.setItem('username', user.firstname + ' ' + user.lastname);
					sessionStorage.setItem('avatar', user.avatar);
					sessionStorage.setItem('email', user.email);
					sessionStorage.setItem('phone', user.phone);
					sessionStorage.setItem('adress', user.adress);
					sessionStorage.setItem('town', user.town);
					sessionStorage.setItem('country', user.country);
					this.toastr.success('Vous êtes Connecté !', 'Success souscriptor');
					this.router.navigate(['/souscriptor/dashboard']);
					// this.socketservice.sendDocId(user.id).subscribe((userdata: any) => {
					//   this.userdata = userdata;
					//   sessionStorage.setItem('usdocws', JSON.stringify(this.userdata));
					// });
			  }
			}, err => {
			  this.loading = false;
			  this.toastr.error(Errors(err), 'Connection Fail! : Impossible d\'etablir une connexion. Veuillez réessayer après quelques secondes ou contacter le service support.');
			  console.log('error', err);
			  console.log(Errors(err));
			});
        } else {
         this.loading = false;
         this.toastr.info('Votre compte n\'est pas encore activé!', 'Info Message');
        }

    },err => {
       this.loading = false;
       this.toastr.error(Errors(err), 'Connection Fail! : Echec d\'obtention des données. Veuillez réessayer après quelques secondes ou contacter le service support.');
       console.log('err', err);
    });

  }

  getTitle() {
    return this.translate.instant(this.titleKey);
  }

  getSubtitle() {
    return this.translate.instant(this.subtitleKey);
  }
}
