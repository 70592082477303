import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
// import { User } from 'src/app/models/user';
// import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { environment } from 'src/environments/environment';
import paysdata from 'src/app/pays.json';

@Component({
  selector: 'app-edit-profile-admin',
  templateUrl: './edit-profile-admin.component.html',
  styleUrls: ['./edit-profile-admin.component.css']
})
export class EditProfileAdminComponent implements OnInit {

  userForm: FormGroup;
  submitted = false;
  formData = new FormData();
  fileData: File = null;
  user: any = [];
  userId: any;
  baseUrl: string;
  urls: any;
  pays: any;

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router:Router, private toastr: ToastrService) {
  }

  ngOnInit() {

    this.createForm();
    this.userId = sessionStorage.getItem('userId');
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + '/public/files/avatars/avatar.png';
    this.pays = paysdata;
  }

  createForm() {
    this.userForm = this.fb.group({
      name: [this.user.name],
      surname: [this.user.surname, Validators.required],
      birthdate: [this.user.birthdate, Validators.required],
      tel: [this.user.tel, Validators.required],
      email: new FormControl({ value: this.user.email, disabled: true }),
      adress: [this.user.adress, Validators.required],
      postalcode: [this.user.postalcode],
      town: [this.user.town, Validators.required],
      country: [this.user.country, Validators.required]
    });
  }

  onSelectFile(events) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.newPatient.avatar=this.fileData;
      this.formData.append('avatar', this.fileData);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    }else {

    }
  }

  get f() {
    return this.userForm.controls;
  }

  onSumbit(){
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }else{

      let formValue = this.userForm.value;
      this.formData.append('id', this.userId);
      this.formData.append('name', formValue['name']);
      this.formData.append('surname', formValue['surname']);
      this.formData.append('birthdate', formValue['birthdate']);
      // this.formData.append('email', formValue['email']);
      this.formData.append('tel', formValue['tel']);
      // this.formData.append('type', formValue['type']);
      this.formData.append('adress', formValue['adress']);
      this.formData.append('postalcode', formValue['postalcode']);
      this.formData.append('town', formValue['town']);
      this.formData.append('country', formValue['country']);

      this.resourceService.updateResource('/updateuser', this.formData).subscribe(data=>{
        this.toastr.success('Votre compte a été modifié!', 'Sucess');
      },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
      });

    }
  }

  public getUser(){
    this.resourceService.getResource('findone/', this.userId).subscribe(data=>{
      this.user = data;
      this.userForm.patchValue({
        name: this.user.name,
        surname: this.user.surname,
        birthdate: this.user.birthdate,
        tel: this.user.tel,
        email: this.user.email,
        adress: this.user.adress,
        postalcode: this.user.postalcode,
        town: this.user.town,
        country: this.user.country
      });

      if(this.user.avatar != null){
        this.urls = this.baseUrl + 'public/files/avatars/' + this.user.avatar;
      }
    },err=>{
      console.log('erreur',err);
    });
  }

}
