import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Injectable({ providedIn: 'root'})

export class SouscriptorGuardService {
  constructor(private router: Router, private toastr: ToastrService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!localStorage.getItem('token') || sessionStorage.getItem('checksess') !== 'e-Fid@mlm0@SouS') {
      this.toastr.warning('You are not allowed to view this page. You are redirected to login Page', 'Unauthorized !');
      alert('You are not allowed to view this page. You are redirected to login Page');
      this.router.navigate(['/souscriptor/sign-in']);
      return false;
    }

    return true;
  }
}
