import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
import {Souscriptor} from "../../../models/souscriptor";
// import {Shedule} from '../../../models/schedule';
declare var swal: any;

@Component({
  selector: 'app-list-souscriptor',
  templateUrl: './list-souscriptor.component.html',
  styleUrls: ['./list-souscriptor.component.css']
})
export class ListSouscriptorComponent implements OnInit, OnDestroy, AfterViewInit {

  newSouscriptor: Souscriptor;
  souscriptorFormEdit: FormGroup;
  souscriptors: any = [];
  // formData = new FormData();
  fileData: File = null;
  baseUrl: string;
  urls: any;
  url: string = '/user';
  public error;
  public success;
  dtTrigger: Subject <any> = new Subject();
  pays: any;
  date: any;
  submitted = false;
  paymentMode = [
    {
      'id': 0,
      'name': 'Cheques'
    },
    {
      'id': 1,
      'name': 'Especes'
    },
    {
      'id': 2,
      'name': 'Virement Bancaire'
    },
    {
      'id': 3,
      'name': 'Transfert Mobile'
    }
  ];

  public detailsData =
    {
      id: null,
      status: '',
      firstname: '',
      lastname: '',
      birthdate: '',
      matricule: '',
      adress: '',
      postalcode: '',
      town: '',
      country: '',
      email: '',
      phone: '',
      cni_passport: '',
      delivery_date: '',
      delivery_location: '',
      last_connexion: '',
      avatar: ''
    };

  baseurl: string;

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router: Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newSouscriptor = {} as Souscriptor; }

  ngOnInit() {

    this.initFormEdit();
    this.baseurl = environment.baseUrl + '/public/';
    this.baseUrl = environment.baseUrl;
    this.urls = environment.baseUrlImage + '/files/avatars/avatar.png';
    this.pays = paysdata;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  initFormEdit() {
    this.souscriptorFormEdit = this.formBuilder.group({
      id: '',
      checkbox: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      birthdate: [''],
      matricule: ['', Validators.required],
      adress: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', Validators.required],  // Validators.pattern("^[+0-9 ]{6,}$")
      cni_passport: ['', Validators.required],
      delivery_date: ['', Validators.required],
      delivery_location: ['', Validators.required],
    });
  }

  get f1() { return this.souscriptorFormEdit.controls; }

  public liste() {

    this.resourceService.getResources('/souscriptor/all').subscribe(data => {
      this.souscriptors = data;
      console.log('lnx', data);
      this.dtTrigger.next();
    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(souscriptor) {

    this.detailsData =
      {
        id: souscriptor.id ,
        firstname: souscriptor.firstname,
        lastname: souscriptor.lastname,
        birthdate: souscriptor.birthdate,
        matricule: souscriptor.matricule,
        adress: souscriptor.adress,
        postalcode: souscriptor.postalcode,
        town: souscriptor.town,
        country: souscriptor.country,
        email: souscriptor.email,
        phone: souscriptor.phone,
        cni_passport: souscriptor.cni_passport,
        delivery_date: souscriptor.delivery_date,
        delivery_location: souscriptor.delivery_location,
        last_connexion: souscriptor.last_connexion,
        avatar: this.baseurl + souscriptor.avatar,
        status: souscriptor.status
      };
  }

  public onEdit(souscriptor) {
    this.souscriptorFormEdit.patchValue({
      checkbox: souscriptor.checkbox,
      id: souscriptor.id,
      firstname: souscriptor.firstname,
      lastname: souscriptor.lastname,
      birthdate: souscriptor.birthdate,
      matricule: souscriptor.matricule,
      adress: souscriptor.adress,
      postalcode: souscriptor.postalcode,
      town: souscriptor.town,
      country: souscriptor.country,
      email: souscriptor.email,
      phone: souscriptor.phone,
      cni_passport: souscriptor.cni_passport,
      delivery_date: souscriptor.delivery_date,
      delivery_location: souscriptor.delivery_location,
      last_connexion: souscriptor.last_connexion,
      avatar: this.baseurl + souscriptor.avatar,
      // avatar: 'public' + user.avatar
      // avatar: this.baseUrl + 'public' + user.avatar
    });
  }

  public updateSouscripter(){
    this.submitted = true;

    if (this.souscriptorFormEdit.invalid || !this.souscriptorFormEdit.value['checkbox']) {
    console.log('block');
      return;
    }
    /*else if (!this.souscriptorFormEdit.value['basic'] && !this.souscriptorFormEdit.value['classic'] && !this.souscriptorFormEdit.value['vip']) {
      console.log('block', this.souscriptorFormEdit.value['basic'], typeof this.souscriptorFormEdit.value['classic']);
      return;
    }
    else if (this.souscriptorFormEdit.value['paymentMode'] !== this.paymentMode[1].name && !this.souscriptorFormEdit.value['transactionNumber']) {
      // Transaction number requires because the payment isn't cash face
      console.log('not cash flow face start');
      return;
    }*/

    this.date = new Date();
    let formData = new FormData();
    const formValue = this.souscriptorFormEdit.value;

    formData.append('id', formValue['id']);
    formData.append('firstname', formValue['firstname']);
    formData.append('lastname', formValue['lastname']);
    formData.append('birthdate', formValue['birthdate']);
    formData.append('matricule', formValue['matricule']);
    formData.append('adress', formValue['adress']);
    formData.append('postalcode', formValue['postalcode']);
    formData.append('town', formValue['town']);
    formData.append('country', formValue['country']);
    formData.append('email', formValue['email']);
    formData.append('phone', formValue['phone']);
    formData.append('cni_passport', formValue['cni_passport']);
    formData.append('delivery_date', formValue['delivery_date']);
    formData.append('delivery_location', formValue['delivery_location']);
    formData.append('date_updated', this.date);
    /*formData.append('basic', formValue['basic'].value);
    formData.append('classic', formValue['classic'].value);
    formData.append('vip', formValue['vip'].value);
    formData.append('transaction_number', formValue['transactionNumber']);
    formData.append('payment_mode', formValue['paymentMode']);*/

    // formData.append('status', '0');
    // formData.append('connected', '0');
    // formData.append('date_created', this.date);
    // formData.append('last_connexion', this.date);

    if(this.fileData){
      formData.append('avatar', this.fileData );
    }

    this.resourceService.updateResource('/update/souscriptor', formData).subscribe(data => {

      console.log('data', data);
      this.liste();
      this.resetForm(this.souscriptorFormEdit);
      this.toastr.success('Les données du souscripteur ont été mis à jour!', 'Sucess');
      this.router.navigate(['/admin/list/souscriptor']);

    }, error => {
      if(error.status == 500 || error.status == 503){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
      if(error.status == 400){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
    });
  }

  public onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData );
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {
    }
  }

  public resetForm(form){
    form.reset();
  }

  public  deleteSouscriptor(event: MouseEvent, user) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('/delsouscriptor', user.id).subscribe(data => {
          this.souscriptors = data;
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/souscriptor/deleteall').subscribe(data => {
          this.souscriptors = data;
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

}
