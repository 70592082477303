import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-gains',
  templateUrl: './my-gains.component.html',
  styleUrls: ['./my-gains.component.css']
})
export class MyGainsComponent implements OnInit, OnDestroy {

	
  

	constructor(private resourceService: ResourceService, private toastr: ToastrService) { }

	ngOnInit() {
    // this.liste();
    
	}
	ngOnDestroy(){
    //this.dtTrigger.unsubscribe();
  } 

	// public liste() {

 //    this.resourceService.getResources('/gain/all').subscribe(data => {
 //      console.log(data);
 //      this.dtTrigger.next();
 //    }, error => {
 //      if(error.status==500 || error.status==503){

 //        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
 //        console.log('erreur',error);
 //        console.log(Errors(error));
 //      }
 //    });
 //  }
}
