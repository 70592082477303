import { Component, OnInit, OnDestroy } from '@angular/core';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-my-memberships',
  templateUrl: './my-memberships.component.html',
  styleUrls: ['./my-memberships.component.css']
})
export class MyMembershipsComponent implements OnInit, OnDestroy {
	memberships: any = [];
  dtTrigger: Subject <any> = new Subject();
  constructor(private resourceService: ResourceService, private toastr: ToastrService) { }

  ngOnInit() {
  	this.liste();	
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }
  public liste() {

    this.resourceService.getResources('/investment/memberships/all/').subscribe(data => {
     this.memberships = data;
     console.log(data);
      
    }, error => {
      if(error.status==500 || error.status==503){
        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }
}
