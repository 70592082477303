import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/socket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-souscriptor',
  templateUrl: './header-souscriptor.component.html',
  styleUrls: ['./header-souscriptor.component.css']
})
export class HeaderSouscriptorComponent implements OnInit {

  userId: any;
  public currentlang: string = '';

  constructor(private translateService: TranslateService, private resourceService: ResourceService, private router: Router,private socketService :SocketService) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');

    let lang: any = navigator.language;
    lang = lang.split('-');

    if (lang[0] === 'fr') {
      this.translateService.setDefaultLang('fr');
      this.currentlang = 'fr';
    } else {
      this.translateService.setDefaultLang('en');
      this.currentlang = 'en';
    }
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
    this.currentlang = language;
  }

  logout(){
    this.resourceService.getResource('/souscriptor/logout', this.userId).subscribe(data => {

      /* let users={
        id:this.userId,
        type:"souscriptor"
      }
      this.socketService.logoutSocket(JSON.stringify(users) ); */
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/souscriptor/sign-in']);
    } );
  }
}
