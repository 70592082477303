import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private titleKey: string = "home";
  private subtitleKey: string = "home-desc";

  constructor(private title: Title, private translate: TranslateService) {
    this.title.setTitle(this.getTitle());
  }

  ngOnInit() {}

  getTitle() {
    return this.translate.instant(this.titleKey);
  }

  getSubtitle() {
    return this.translate.instant(this.subtitleKey);
  }
}
