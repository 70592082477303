import { Component, OnInit} from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-souscriptions',
  templateUrl: './my-souscriptions.component.html',
  styleUrls: ['./my-souscriptions.component.css']
})
export class MySouscriptionsComponent implements OnInit{

  public error;
  public success;
  souscriptions: any= [];
  userId: any;

  constructor(private resourceService: ResourceService) {
    this.userId = sessionStorage.getItem("userId");
  }

  ngOnInit(){
    this.liste();
  }

  
  public liste() {
  
    this.resourceService.getResource('/investment/mydata/all', this.userId).subscribe(data => {
      console.log(data);
      this.souscriptions = data;
    }, error => {
      console.log('erreur',error);

    });
  }
}

