import { Component, OnInit } from '@angular/core';
import paysdata from 'src/app/pays.json';
import { ResourceService } from 'src/app/services/resource.service';
import { Souscriptor } from 'src/app/models/souscriptor';
import { FormGroup, FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-edit-profile-souscriptor',
  templateUrl: './edit-profile-souscriptor.component.html',
  styleUrls: ['./edit-profile-souscriptor.component.css']
})
export class EditProfileSouscriptorComponent implements OnInit {

	editAccount: FormGroup;
	editPassword: FormGroup;

	souscriptor: any;
	userId: any;
	country: any;

	constructor(private fb: FormBuilder, private serviceResource: ResourceService) {
    	this.userId = sessionStorage.getItem('userId');
	    this.souscriptor = {} as Souscriptor;
	}

	ngOnInit() {
    	this.country = paysdata;
    	setTimeout(() =>{
    		console.log(this.getSouscriptor());
    	}, 10000);
		this.initForm();
	    
	}

	initForm(){		
		this.editAccount = this.fb.group({
			firstname: "Kurosaki"
		});
	}

	// createFormAccount(){
	// 	console.log(this.souscriptor)
	// 	this.editAccount = this.fb.group({
	// 		firstname: this.souscriptor.firstname,
	// 		lastname: this.souscriptor.lastname,
	// 		email: this.souscriptor.email,
	// 		phone: this.souscriptor.phone,
	// 		adress: this.souscriptor.adress,
	// 		town: this.souscriptor.town,
	// 		pays: this.souscriptor.country
	// 	});
	// }

	getSouscriptor() {
	    this.serviceResource.getResource('/souscriptor/mydata', this.userId).subscribe(data => {
	      	return data;
	    }, err => {
	      console.log('erreur', err);
	      return err;
	    });
  	}

}
