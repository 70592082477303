import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import paysdata from 'src/app/pays.json';
import { environment } from 'src/environments/environment';
import { Errors } from 'src/app/settings/function_errors';

@Component({
  selector: 'app-add-partner',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.css']
})
export class AddPartnerComponent implements OnInit {
  
  partnerForm: FormGroup;
  submitted = false;
  urls: any = "assets/images/xs/user.png";
  fileData: File = null;
  pays: any;
  baseUrl: string;
  date: any;

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.checkSession();
    this.pays = paysdata;
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + '/public/files/avatars/avatar.png';
    this.createForm();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/partner"]);
    }
  }
  
  createForm() {

    this.partnerForm = this.fb.group({
      checkbox: ['', Validators.required],
      name: ['', Validators.required],
      adress: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
    });
  }

  get f1() {
    return this.partnerForm.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.partnerForm.invalid  || !this.partnerForm.value['checkbox']) {
      return;
    }else{

      this.date = new Date().toDateString();
      let formData = new FormData();
      let formValue = this.partnerForm.value;
      formData.append('name', formValue['name']);
      formData.append('adress', formValue['adress']);
      formData.append('postalcode', formValue['postalcode']);
      formData.append('email', formValue['email']);
      formData.append('phone', formValue['phone']);
      formData.append('town', formValue['town']);
      formData.append('country', formValue['country']);
      formData.append('deleted', '0');
      formData.append('date_created', this.date);

      
    
    alert(this.fileData)
      
      if(this.fileData){
        formData.append('logo', this.fileData );
      }

      // Save datas create user in database
      // this.resourceService.saveResource('/partner/create', formData).subscribe(
      //   (data) => {
      //     let res:any = data;
      //     if (res == "Erreur") {
      //       this.toastr.error('Votre compte ne peut etre crée! E-mail deja existant!', 'Error');
      //     }else{
      //       this.partnerForm.reset();
      //       this.toastr.success('Votre compte a été crée!', 'Sucess');
      //       this.router.navigate(['/admin/confirm/action']);
      //     }
      //   }, 
      //   err => {
      //     this.toastr.error(Errors(err), 'Major Error');
      //     console.log('erreur', err);
      //     console.log(Errors(err));
      //   });
    }
  }


  onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      alert("enter")
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
    
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {
      alert("enter1")
    }

  }


}
