import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-our-vision',
  templateUrl: './our-vision.component.html',
  styleUrls: ['./our-vision.component.scss']
})
export class OurVisionComponent implements OnInit {
  private titleKey: string = "our-vision";
  private subtitleKey: string = "our-vision-desc";

  constructor(private title: Title, private translate: TranslateService) {
    this.title.setTitle(this.getTitle());
  }

  ngOnInit() {}

  getTitle() {
    return this.translate.instant(this.titleKey);
  }

  getSubtitle() {
    return this.translate.instant(this.subtitleKey);
  }
}
