import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import{Errors} from '../../../settings/function_errors';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss', "../../../../assets/css/font-awesome.min.css"]
})
export class ForgetPasswordComponent implements OnInit {
  private titleKey: string = "password-forget";
  private subtitleKey: string = "password-forget-desc";

  angForm: FormGroup;
  submitted = false;
  loading = false;
  constructor(private title:Title,
              private fb: FormBuilder,
              private resourceService: ResourceService,
              private translate: TranslateService,
              private toastr: ToastrService) {
    this.title.setTitle(this.getTitle());
  }

  ngOnInit() {
    this.createForm();

  }

  createForm() {
    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.angForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.angForm.invalid) {
      return;
    }
    this.loading = true;
    let email = this.angForm.value['email'];

    this.resourceService.getResources_unsafe('sendrecoverymail/'+email).subscribe(data=>{
      this.toastr.success('Votre mot de passe a ete reinitialise, veuillez consulter vos mails', 'Sucess');
      this.angForm.controls.email.setValue(null);
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    },()=>{
        this.loading=false;
    });
  }

  getTitle() {
    return this.translate.instant(this.titleKey);
  }

  getSubtitle() {
    return this.translate.instant(this.subtitleKey);
  }
}
