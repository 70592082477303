import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {ResourceService} from "../../../services/resource.service";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../../environments/environment";
import {Errors} from "../../../settings/function_errors";

@Component({
  selector: 'app-list-memberships',
  templateUrl: './list-memberships.component.html',
  styleUrls: ['./list-memberships.component.css']
})
export class ListMembershipsComponent implements OnInit {
  @ViewChild('closemodal', {static: true}) closemodal;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  dtTrigger: Subject <any> = new Subject();

  memberships: any = [];
  sorted: any = [];
  baseUrl: string;
  detailsData = {
    id : 0,
    souscriptor: {
    	firstname: null,
    	lastname: null
    },
    type: null,
    payment: {
    	amount: null,
    	payment_mode: null,
    	transaction_number: null,
    },
    nature: null,
    date: null
  };
  /*typeFilter = [{'name': 'All'}]; = [
    {'name': 'All'},
    {'name': 'Classic'},
    {'name': 'Basic'},
    {'name': 'Vip'},
  ];*/
  paymentModeFilter: any; /* = [
    {'name': 'All'},
    {'name': 'Cheques'},
    {'name': 'Especes'},
    {'name': 'Virement Bancaire'},
    {'name': 'Transfert Mobile'}
  ];*/
  originFilter: any; /* = [
    {'name': 'All'},
    {'name': 'Auto'},
    {'name': 'Manual'},
  ];*/
  typeFilterSelected = "All";
  paymentModeFilterSelected = "All";
  originFilterSelected = "All";

  constructor(private resourceService: ResourceService,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.baseUrl = environment.baseUrl;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }
  
  /*private setPaymentMode() {
    let a : string = '';
    
  	if (this.memberships) {
  		for (var i=0; i<this.memberships.length; i++) {
  			let b = this.memberships[i].type;
  			
  			if (a.indexOf(b+';') < 0) {
  				this.typeFilter.push({'name': b});
  				a += (b + ';');
  			}
  		}
  	}
  }*/

  public liste() {

    this.resourceService.getResources('/investment/memberships/all/').subscribe(data => {
     this.memberships = data;
     // this.setPaymentMode();
     this.sort();

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      console.log(data, typeof data);
      
    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(member){
    this.detailsData = {
    id : member.id,
    souscriptor: member.souscriptor,
    type: member.type,
    payment: member.payment,
    nature: member.nature,
    date: member.date
  };

    console.log(this.detailsData.id);
  }
  
  private sortMembershipsWithPaymentMode() {
    if (this.paymentModeFilterSelected !== 'All')  {
      let newSorted = [];

      for (let i=0; i<this.sorted.length; i++) {
        if (this.sorted[i].payment.payment_mode.toLowerCase() === this.paymentModeFilterSelected.toLowerCase()) 
        	newSorted.push(this.sorted[i]);
      }

      this.sorted = newSorted;
    }
  }

  private sortMembershipsWithOrigin() {
    if (this.originFilterSelected !== 'All')  {
      let newSorted = [];

      for (let i=0; i<this.sorted.length; i++) {
        if (this.sorted[i].nature.toLowerCase() === this.originFilterSelected.toLowerCase()) 
        	newSorted.push(this.sorted[i]);
      }

      this.sorted = newSorted;
    }
  }

  private sortWithTypeOfSubscriptions() {
    if (this.typeFilterSelected !== 'All')  {
      let newSorted = [];

      for (let i=0; i<this.sorted.length; i++) {
        if (this.sorted[i].type.toLowerCase() === this.typeFilterSelected.toLowerCase()) 
        	newSorted.push(this.sorted[i]);
      }

      this.sorted = newSorted;
    }
  }

  private isOnlyNumberCharacters(data: string) : boolean {
    const only = '0123456789';

    if (data.length == 0) return false;

    for (let i=0; i<data.length; i++) {
      if (only.indexOf(data[i]) < 0) return false;
    }

    return true;
  }

  private sortWithDate() {
    const year = document.getElementById("yearSort").getAttribute("value");
    const month = document.getElementById("monthSort").getAttribute("value");
    const day = document.getElementById("daySort").getAttribute("value");
    let yearMax = null;
    let yearMin = null;
    let yearNow = null;
    let monthMax = null;
    let monthMin = null;
    let monthNow = null;
    let dayMin = null;
    let dayMax = null;
    let dayNow = null;

    if (year !== null && year !== undefined && year.length > 0) {
      if (("" + year).indexOf('-') > 0) {
        let a = year.split('-')[0];
        let b = year.split('-')[1];

        if (this.isOnlyNumberCharacters(a) && this.isOnlyNumberCharacters(b) && parseInt(a) <= parseInt(b)) {
          yearMin = parseInt(a);
          yearMax = parseInt(b);
        }
      }
      else if (this.isOnlyNumberCharacters(year)) {
        yearNow = parseInt(year);
      }
    }

    if (month !== null && month !== undefined && month.length > 0) {
      if (("" + month).indexOf('-') > 0) {
        let a = month.split('-')[0];
        let b = month.split('-')[1];

        if (this.isOnlyNumberCharacters(a) && this.isOnlyNumberCharacters(b) && parseInt(a) <= parseInt(b)) {
          monthMin = parseInt(a);
          monthMax = parseInt(b);
        }
      }
      else if (this.isOnlyNumberCharacters(month)) {
        monthNow = parseInt(month);
      }
    }

    if (day !== null && day !== undefined && day.length > 0) {
      if (("" + day).indexOf('-') > 0) {
        let a = day.split('-')[0];
        let b = day.split('-')[1];

        if (this.isOnlyNumberCharacters(a) && this.isOnlyNumberCharacters(b) && parseInt(a) <= parseInt(b)) {
          dayMin = parseInt(a);
          dayMax = parseInt(b);
        }
      }
      else if (this.isOnlyNumberCharacters(day)) {
        dayNow = parseInt(day);
      }
    }

    // --- Begin sort
    let newSorted = [];

    for (let i=0; i<this.sorted.length; i++) {
      const listDate = this.sorted[i].date.split(":");  // Year:Month:Date:....
      let isAuthYear = false;
      let isAuthMonth = false;
      let isAuthDay = false;

      // --- Sort With Year
      if (yearMin != null && yearMax != null) {
        if (yearMin >= parseInt(listDate[0]) && parseInt(listDate[0]) <= yearMax) isAuthYear = true;
      }
      else if (yearNow != null) {
        if (yearNow == parseInt(listDate[0])) isAuthYear = true;
      }
      else isAuthYear = true;

      // --- Sort With Month
      if (isAuthYear) {
        if (monthMin != null && monthMax != null) {
          if (monthMin >= parseInt(listDate[1]) && parseInt(listDate[1]) <= monthMax) isAuthMonth = true;
        }
        else if (monthNow != null) {
          if (monthNow == parseInt(listDate[1])) isAuthMonth = true;
        }
        else isAuthMonth = true;
      }

      // --- Sort With Day
      if (isAuthYear && isAuthMonth) {
        if (dayMin != null && dayMax != null) {
          if (dayMin >= parseInt(listDate[2]) && parseInt(listDate[2]) <= dayMax) isAuthDay = true;
        }
        else if (dayNow != null) {
          if (dayNow == parseInt(listDate[2])) isAuthDay = true;
        }
        else isAuthDay = true;
      }

      if (isAuthYear && isAuthMonth && isAuthDay) newSorted.push(this.sorted[i]);
    }

    this.sorted = newSorted;
  }

  public parseDate(date: string) : Date {
    if (!date) return new Date();
    const a = date.split(':');

    return new Date(parseInt(a[0]), parseInt(a[1]), parseInt(a[2]), parseInt(a[3]), parseInt(a[4]));
  }

  public totalFunds() : any {
    let total = 0;

    for (let i=0; i<this.sorted.length; i++)
      total += this.sorted[i].amount;

    return total;
  }

  private sort() {
    this.sorted = this.memberships;
    this.sortMembershipsWithOrigin();
    this.sortMembershipsWithPaymentMode();
    this.sortWithTypeOfSubscriptions();
    //this.sortWithDate();
  }
  
  public selectChange(state: number) {
  	if (state === 0) this.typeFilterSelected = document.getElementById("typeSelect").getAttribute('value') || 'All';
  	else if (state === 1) this.paymentModeFilterSelected = document.getElementById("paymentModeSelect").getAttribute('value') || 'All';
  	else this.originFilterSelected = document.getElementById("originSelect").getAttribute('value') || 'All';
  	
  	console.log('lnox', this.typeFilterSelected, this.paymentModeFilterSelected, this.originFilterSelected);
  	
  	// if (state === 0) this.typeFilterSelected = select.options[select.selectedIndex].value;
  	// else if (state === 1) this.paymentModeFilterSelected = select.options[select.selectedIndex].value;
  	// else this.originFilterSelected = select.options[select.selectedIndex].value;
  	
  	this.sort();  // Sort
  }
}
