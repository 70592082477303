import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-site',
  templateUrl: './footer-site.component.html',
  styleUrls: ['./footer-site.component.scss', "../../../../assets/css/font-awesome.min.css"]
})
export class FooterSiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
