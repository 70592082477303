import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-password-souscriptor',
  templateUrl: './edit-password-souscriptor.component.html',
  styleUrls: ['./edit-password-souscriptor.component.css']
})
export class EditPasswordSouscriptorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
