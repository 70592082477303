import { Component, OnInit} from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-my-sponsorships',
  templateUrl: './my-sponsorships.component.html',
  styleUrls: ['./my-sponsorships.component.css']
})
export class MySponsorshipsComponent implements OnInit {

  public error;
  public success;
  sponsorships: any= [];
  //dtTrigger: Subject<any> = new Subject();


  constructor(private resourceService: ResourceService) {
    
  }

  ngOnInit(){
    //this.liste();
  }

  //
  // public liste() {
  //
  //   this.resourceService.getResources('role/all').subscribe(data => {
  //
  //     this.sponsorships = data;
  //     this.dtTrigger.next();
  //   }, error => {
  //     if(error.status==500 || error.status==503){
  //
  //       this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
  //       console.log('erreur',error);
  //       console.log(Errors(error));
  //     }
  //   });
  // }

}
