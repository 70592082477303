import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule, ROUTES } from '../app/routing/app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule} from '../app/layout/layout.module';
import { PagesModule } from '../app/pages/pages.module';
import { UiModule } from '../app/ui/ui.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxLoadingModule } from 'ngx-loading';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { SocketserviceService } from './services/socketservice.service';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  declarations: [ ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    PagesModule,
    UiModule,
	  NgxLoadingModule.forRoot({}),
    // RouterModule.forRoot(ROUTES),
    RouterModule.forRoot(ROUTES, { useHash: true }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],

  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
