import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../../settings/must-match.validator';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';

@Component({
  selector: 'app-edit-password-admin',
  templateUrl: './edit-password-admin.component.html',
  styleUrls: ['./edit-password-admin.component.css']
})
export class EditPasswordAdminComponent implements OnInit {

  angForm: FormGroup;
  submitted = false;
  formData = new FormData();
  userId: any;

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router:Router, private toastr: ToastrService) { }

  ngOnInit() {

    this.createForm();
    this.userId = sessionStorage.getItem('userId');
  }

  createForm() {
    this.angForm = this.fb.group({
        old_password: ['',[Validators.required, Validators.minLength(6)]],
        new_password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=[^A-Z]*[A-Z])(?=\\D*\\d)[A-Z\\d!$%@#£€*?&]{6,}$')]],
        confirm_password: ['', [Validators.required, Validators.minLength(6)] ]
      },
      {
        validator: MustMatch('new_password', 'confirm_password')
      });
  }

  get f() {
    console.log(this.angForm.controls);
    return this.angForm.controls;
  }


  onSubmit(){

    this.submitted = true;

    if (this.angForm.invalid) {
      return;
    } else {
      // fonction qui permet de verifier si le mot actuel est correcte
      let datas = {
        password: sha256(this.angForm.value['old_password']),
        id: this.userId
      }

      this.resourceService.saveResource('user/checkpassword', datas).subscribe(data => {
        // fonction qui  modifie le mot de l'utilisateur

        let datas = {
          password: sha256(this.angForm.value['new_password']),
          id: this.userId
        }

        this.resourceService.updateResource('user/updatepasswd', datas).subscribe( data => {
            this.toastr.success('Votre mot de passse a été modifié!', 'Sucess');
          },
          err => {
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
          });

      },err=>{
        this.toastr.error('Mot de passe incorrect!', 'Major Error');
      })
    }
  }

}
