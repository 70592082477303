import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import paysdata from 'src/app/pays.json';
import {Role} from '../../../models/role';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  userForm: FormGroup;
  submitted = false;
  urls: any = "assets/images/xs/user.png";
  fileData: File = null;
  pays: any;
  baseUrl: string;
  roles: any = [];
  date: any;

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {

    this.pays = paysdata;
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + 'public/files/avatars/avatar.png';
    this.createForm();
    this.getRoles();
  }

  createForm() {
    this.userForm = this.fb.group({
      checkbox: ['', Validators.required],
      name: ['', Validators.required],
      surname: [''],
      birthdate: [''],
      tel: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      adress: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: [null, Validators.required],
      type: ['', Validators.required]
    });
  }

  checkemail(value){
    this.resourceService.getResources_unsafe('user/checkemail/'+value).subscribe((data) => {
      if(data == "Done")
        this.toastr.error('Cette adresse e-mail existe deja', 'Error');
    });
  }

  get f1() {
    return this.userForm.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.userForm.invalid  || !this.userForm.value['checkbox']) {
      return;
    }else{
      this.date = new Date().toDateString();
      let formData = new FormData();
      let formValue = this.userForm.value;

      formData.append('username', formValue['name']);
      formData.append('lastname', formValue['surname']);
      formData.append('birthdate', formValue['birthdate']);
      formData.append('email', formValue['email']);
      formData.append('tel', formValue['tel']);
      formData.append('type', formValue['type']);
      formData.append('adress', formValue['adress']);
      formData.append('postalcode', formValue['postalcode']);
      formData.append('town', formValue['town']);
      formData.append('country', formValue['country']);
      formData.append('status', '0');
      formData.append('connected', '0');
      formData.append('date_created', this.date);
      // formData.append('date_updated', this.date);
      // formData.append('last_connexion', this.date);

      if(this.fileData){
        formData.append('avatar', this.fileData );
      }

      // Save datas create user in database
      this.resourceService.saveResource('createuser', formData).subscribe((data) => {

        let res: any = data;
        if (res == "Erreur") {
          this.toastr.error('Votre compte ne peut etre crée! E-mail deja existant!', 'Error');
        }else{
          this.userForm.reset();
          this.toastr.success('Votre compte a été crée!', 'Sucess');
          this.router.navigate(['/admin/confirm/action']);
        }
      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
        console.log(Errors(err));
      });
    }
  }


  onSelectFile(events, f: NgForm) {
    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {

    }
  }

  getRoles(){
    this.resourceService.getResources('role/all').subscribe(data => {
      this.roles = data;
    } ,err => {
      console.log('erreur', err);
    });
  }

}
