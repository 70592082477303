import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
  import { Router } from '@angular/router';
  import { ResourceService } from 'src/app/services/resource.service';
import { Souscriptor } from 'src/app/models/souscriptor';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar-souscriptor',
  templateUrl: './sidebar-souscriptor.component.html',
  styleUrls: ['./sidebar-souscriptor.component.css']
})

export class SidebarSouscriptorComponent implements OnInit, OnDestroy, AfterViewInit {

  userId: any;

  baseUrl = environment.baseUrlImage;
  public url: any;
  souscriptor: any;
  subs: Subscription;
  urls: string;

  constructor( private serviceResource: ResourceService, private translateService: TranslateService) {
    this.souscriptor = {} as Souscriptor;
    this.userId = sessionStorage.getItem('userId');
    this.subs = this.serviceResource.getData().subscribe(data => {
      console.log('getdata', data);
      this.souscriptor = data;
    });
  }

  ngOnInit() {
    this.baseUrl += "/";
  }

  ngAfterViewInit() {
    this.getSouscriptor();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getSouscriptor() {
    this.serviceResource.getResource('/souscriptor/mydata', this.userId).subscribe(data => {
      
      this.souscriptor = data;
    }, err => {
      console.log('erreur', err);
    });
  }

}
