import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Role } from 'src/app/models/role';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})

export class AddRoleComponent implements OnInit {

  newRole:Role;
  roleForm: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder, private resourceService:ResourceService, private router:Router, private toastr: ToastrService) {
    this.newRole={} as Role;
  }

  ngOnInit() {

    this.createForm();
  }

  createForm() {
    this.roleForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      value: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.roleForm.invalid ) {
      return;
    } else {

      let formValue = this.roleForm.value;
      this.newRole.id = null;
      this.newRole.name = formValue['name'];
      this.newRole.description = formValue['description'];
      this.newRole.value = formValue['value'];
      this.newRole.date_created = new Date();
      this.newRole.date_updated = new Date();

      // Save datas create role in database
      this.resourceService.saveResource('role/create', this.newRole).subscribe((data) => {

        console.log('data', data);
        this.roleForm.reset();
        this.toastr.success('Le role a été crée!', 'Sucess');
        this.router.navigate(['/admin/confirm/action']);

      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
        console.log(Errors(err));
      });

    }
  }

  get f1() {
    // console.log(this.roleForm.controls);
    return this.roleForm.controls;
  }

}

