import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  private titleKey: string = "faqs";
  private subtitleKey: string = "faqs-desc";

  constructor(private title: Title, private translate: TranslateService) {
    this.title.setTitle(this.getTitle());
  }

  ngOnInit() {
    $('.faq-title').click(function (e) {
      $(e.target.nextElementSibling).toggleClass('delete');
    });
  }

  getTitle() {
    return this.translate.instant(this.titleKey);
  }

  getSubtitle() {
    return this.translate.instant(this.subtitleKey);
  }
}
