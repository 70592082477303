import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderAdminComponent } from './admin/header-admin/header-admin.component';
import { SidebarAdminComponent } from './admin/sidebar-admin/sidebar-admin.component';
import { ChatLauncherAdminComponent } from './admin/chat-launcher-admin/chat-launcher-admin.component';
import { LayoutAdminComponent } from './admin/layout-admin/layout-admin.component';

import { ChatLauncherSouscriptorComponent } from './souscriptor/chat-launcher-souscriptor/chat-launcher-souscriptor.component';
import { HeaderSouscriptorComponent } from './souscriptor/header-souscriptor/header-souscriptor.component';
import { LayoutSouscriptorComponent } from './souscriptor/layout-souscriptor/layout-souscriptor.component';
import { SidebarSouscriptorComponent } from './souscriptor/sidebar-souscriptor/sidebar-souscriptor.component';

import { HeaderSiteComponent } from './site/header-site/header-site.component';
import { FooterSiteComponent } from './site/footer-site/footer-site.component';
import { LayoutSiteComponent } from './site/layout-site/layout-site.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
// import { HeaderComponent } from './global/header/header.component';
// import { FooterComponent } from './global/footer/footer.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DesignSiteComponent } from './site/design-site/design-site.component';

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

    declarations: [
        // HeaderComponent,
        // FooterComponent,
        LayoutAdminComponent,
        HeaderAdminComponent,
        SidebarAdminComponent,
        ChatLauncherAdminComponent,
        LayoutSiteComponent,
        HeaderSiteComponent,
        FooterSiteComponent,
        ChatLauncherSouscriptorComponent,
        HeaderSouscriptorComponent,
        LayoutSouscriptorComponent,
        SidebarSouscriptorComponent,
        DesignSiteComponent,
    ],

    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],

    exports: [
        DesignSiteComponent
    ]
})
export class LayoutModule {
}
