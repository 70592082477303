import { Component, OnInit } from '@angular/core';
import { Gain } from 'src/app/models/gain';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';

@Component({
  selector: 'app-add-gain',
  templateUrl: './add-gain.component.html',
  styleUrls: ['./add-gain.component.css']
})
export class AddGainComponent implements OnInit {


  newGain:Gain;
  gainForm: FormGroup;
  submitted = false;
  levels: any;
  //['1','2','3','4','5']
  partners: any;
  //["HWMG","YEEKONG","LONGRICH","SAURI",];

  constructor(private fb: FormBuilder, private resourceService:ResourceService, private router:Router, private toastr: ToastrService) {
    this.newGain = {} as Gain;
  }

  ngOnInit() {
    this.getPartners();
    this.getLevels();
    this.createForm();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  createForm() {
    this.gainForm = this.fb.group({
      level_id: ['', Validators.required],
      type: ['', Validators.required],
      cash: "",
      dietary_supplements: "",
      foods: "",
      vouchers: "",
      vehicle: "",
      travel: "",
      building_materials: "",
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.gainForm.invalid ) {
      return;
    } else {

      let formValue = this.gainForm.value;
      this.newGain.id = null;
      this.newGain.level_id = parseInt(formValue['level_id']);
      this.newGain.type = formValue['type'];
      this.newGain.cash = formValue['cash'];
      this.newGain.dietary_supplements = formValue["dietary_supplements"];
      this.newGain.foods = formValue['foods'];
      this.newGain.vouchers = formValue['vouchers'];
      this.newGain.vehicle = formValue['vehicle'];
      this.newGain.travel = formValue['travel'];
      this.newGain.building_materials = formValue['building_materials'];
      this.newGain.date_created = new Date();

	  let datas = {
		newGain: this.newGain,
        partnerAmount: this.getPartnerForm()
      };

      // Save datas create gain in database
	  this.resourceService.saveResource('/gain/create', datas).subscribe((data) => {
          let res: any = data;

          if (res !== 'Done'){
            this.toastr.error('Le gain n\'a pas été crée !', 'Error');
          } else {
            this.gainForm.reset();
            this.toastr.success('Le gain a été crée !', 'Sucess');
			this.router.navigate(['/admin/confirm/action']);
          }

       }, err => {
          this.toastr.error(Errors(err), 'Major Error');
          console.log('erreur', err);
          console.log(Errors(err));
       });
	}
  }


  getPartnerForm() {
    var inputs = document.querySelectorAll(".partner_input");
    var arr: any = [];
    for(var i = 0; i < inputs.length; i++){
      if(inputs[i]['value'] !== ""){
        arr.push({
          amount: inputs[i]['value'],
          partner_id: this.partners[i].id
        });
      }
    }
    return arr;
  }

  get f1() {
    // console.log(this.gainForm.controls);
    return this.gainForm.controls;
  }

  //evenement permettant de charger les niveaux(level)
  onChangeType(){
    if (this.gainForm.value["type"] === "basic") {
      let result = this.levels.splice(0,3);
    }else if(this.gainForm.value["type"] === "classic" || this.gainForm.value["type"] === "vip"){
      this.levels = this.getLevels();
    }
  }

  onChecked(e){
    if(e.target.nextElementSibling.parentNode.nextElementSibling.firstElementChild.hasAttribute("disabled")){
      e.target.nextElementSibling.parentNode.nextElementSibling.firstElementChild.removeAttribute("disabled");
    }else{
      e.target.nextElementSibling.parentNode.nextElementSibling.firstElementChild.value = null;
      e.target.nextElementSibling.parentNode.nextElementSibling.firstElementChild.setAttribute("disabled", "true");
    }

  }


  //remplacer this.levels par getLevel()
  getLevels(){
    this.resourceService.getResources('/level/all').subscribe(data => {
      this.levels = data;
    } ,err => {
      console.log('erreur : ', err);
    });
  }

  getPartners(){
    this.resourceService.getResources("/partner/all").subscribe (data => {
        console.log(data);
        this.partners = data;
      },
      err => {
        console.log('erreur : ', err)
      }
    );
  }

}
