// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  spkKey: 'pk_test_IiguzLQwRl1FqZUyZD3nFSIT001yL0bPZR',
  baseUrl: 'https://fidele-mlm-api.herokuapp.com',
  baseUrlImage: 'https://fidele-mlm-api.herokuapp.com/public',
  socketUrl: 'https://fidele-mlm-api.herokuapp.com'

  /* baseUrl: 'http://127.0.0.1:5100',
  baseUrlImage: 'http://127.0.0.1:5100/public',
  socketUrl: 'https://127.0.0.1:5100' */
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
