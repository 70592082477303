import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-site',
  templateUrl: './design-site.component.html',
  styleUrls: ['./design-site.component.scss']
})
export class DesignSiteComponent implements OnInit {
  @Input() title: any;
  @Input() subtitle: any;

  constructor() { }

  ngOnInit() {}
}
