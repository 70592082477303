import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare  var $: any;

@Component({
  selector: 'app-header-site',
  templateUrl: './header-site.component.html',
  styleUrls: ['./header-site.component.scss', "../../../../assets/css/font-awesome.min.css"]
})
export class HeaderSiteComponent implements OnInit {

  public currentlang: string = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    let lang: any = navigator.language;
    lang = lang.split('-');

    if (lang[0] === 'fr') {
        this.translateService.setDefaultLang('fr');
        this.currentlang = 'fr';
    } else {
        this.translateService.setDefaultLang('en');
        this.currentlang = 'en';
    }
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
    this.currentlang = language;
  }

  navigation() {
    $(document).ready(function () {
      'use strict';

      $('.nav-section').toggleClass('active');
    });
  }
}
