import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {ResourceService} from "../../../services/resource.service";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../../environments/environment";
import {Errors} from "../../../settings/function_errors";

@Component({
  selector: 'app-list-partners-memberships',
  templateUrl: './list-partners-memberships.component.html',
  styleUrls: ['./list-partners-memberships.component.css']
})
export class ListPartnersMembershipsComponent implements OnInit {
  @ViewChild('closemodal', {static: true}) closemodal;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  dtTrigger: Subject <any> = new Subject();

  memberships: any = [];
  sorted: any = [];
  baseUrl: string;
  detailsData = {
    id : 0,
    souscriptors: null,
    type: null,
    partners: null,
    amount: null,
    level: 0,
    date : null
  };
  typeFilter = [
    {'name': 'All'},
    {'name': 'Classic'},
    {'name': 'Basic'},
    {'name': 'Vip'},
  ];
  partnersFilter: any;
  souscriptorFilter: any;
  levelFilter = [
    {'name': 'All'},
    {'name': '1'},
    {'name': '2'},
    {'name': '3'},
    {'name': '4'},
    {'name': '5'},
  ];
  typeFilterSelected = "All";
  partnerFilterSelected = "All";
  souscriptorFilterSelected = "All";
  levelFilterSelected = "All";

  constructor(private resourceService: ResourceService,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.baseUrl = environment.baseUrl;
    this.getPartners();
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  public liste() {

    this.resourceService.getResources('/investment/partners/memberships/all/').subscribe(data => {
      /*this.memberships = data;
      /this.sort();

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }*/
      console.log(data, typeof data);

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(member){
    this.detailsData = {
      id : member.id,
      souscriptors: member.souscriptors,
      partners: member.partners,
      type: member.type,
      amount: member.amount,
      level: member.level,
      date: member.date
    };
    console.log(this.detailsData.id);
  }

  private sortMembershipsWithPartners() {
    if (this.partnerFilterSelected !== 'All')  {
      let newSorted = [];

      for (let i=0; i<this.sorted.length; i++) {
        if (this.sorted[i].partners === this.partnerFilterSelected) newSorted.push(this.sorted[i]);
      }

      this.sorted = newSorted;
    }
  }

  private sortMembershipsWithSouscriptors() {
    if (this.souscriptorFilterSelected !== 'All')  {
      let newSorted = [];

      for (let i=0; i<this.sorted.length; i++) {
        if (this.sorted[i].souscriptors === this.souscriptorFilterSelected) newSorted.push(this.sorted[i]);
      }

      this.sorted = newSorted;
    }
  }

  private sortMembershipsWithLevel() {
    if (this.levelFilterSelected !== 'All')  {
      let newSorted = [];

      for (let i=0; i<this.sorted.length; i++) {
        if (this.sorted[i].level === this.levelFilterSelected) newSorted.push(this.sorted[i]);
      }

      this.sorted = newSorted;
    }
  }

  private sortWithTypeOfSubscriptions() {
    if (this.typeFilterSelected !== 'All')  {
      let newSorted = [];

      for (let i=0; i<this.sorted.length; i++) {
        if (this.sorted[i].type === this.typeFilterSelected) newSorted.push(this.sorted[i]);
      }

      this.sorted = newSorted;
    }
  }

  private refreshSouscriptorsFilter() {
    this.souscriptorFilter = [
      {'name': 'All'},
    ];
    let contains = '';

    for (let i=0; i<this.sorted.length; i++) {
      if (contains.indexOf(this.sorted[i].souscriptors) >= 0) {
        this.souscriptorFilter.push({'name': this.sorted[i].souscriptors});
        contains += (this.sorted[i].souscriptors + ";");
      }
    }
  }

  private isOnlyNumberCharacters(data: string) : boolean {
    const only = '0123456789';

    if (data.length == 0) return false;

    for (let i=0; i<data.length; i++) {
      if (only.indexOf(data[i]) < 0) return false;
    }

    return true;
  }

  private sortWithDate() {
    const year = document.getElementById("yearSort").getAttribute("value");
    const month = document.getElementById("monthSort").getAttribute("value");
    const day = document.getElementById("daySort").getAttribute("value");
    let yearMax = null;
    let yearMin = null;
    let yearNow = null;
    let monthMax = null;
    let monthMin = null;
    let monthNow = null;
    let dayMin = null;
    let dayMax = null;
    let dayNow = null;

    if (year !== null && year !== undefined && year.length > 0) {
      if (("" + year).indexOf('-') > 0) {
        let a = year.split('-')[0];
        let b = year.split('-')[1];

        if (this.isOnlyNumberCharacters(a) && this.isOnlyNumberCharacters(b) && parseInt(a) <= parseInt(b)) {
          yearMin = parseInt(a);
          yearMax = parseInt(b);
        }
      }
      else if (this.isOnlyNumberCharacters(year)) {
        yearNow = parseInt(year);
      }
    }

    if (month !== null && month !== undefined && month.length > 0) {
      if (("" + month).indexOf('-') > 0) {
        let a = month.split('-')[0];
        let b = month.split('-')[1];

        if (this.isOnlyNumberCharacters(a) && this.isOnlyNumberCharacters(b) && parseInt(a) <= parseInt(b)) {
          monthMin = parseInt(a);
          monthMax = parseInt(b);
        }
      }
      else if (this.isOnlyNumberCharacters(month)) {
        monthNow = parseInt(month);
      }
    }

    if (day !== null && day !== undefined && day.length > 0) {
      if (("" + day).indexOf('-') > 0) {
        let a = day.split('-')[0];
        let b = day.split('-')[1];

        if (this.isOnlyNumberCharacters(a) && this.isOnlyNumberCharacters(b) && parseInt(a) <= parseInt(b)) {
          dayMin = parseInt(a);
          dayMax = parseInt(b);
        }
      }
      else if (this.isOnlyNumberCharacters(day)) {
        dayNow = parseInt(day);
      }
    }

    // --- Begin sort
    let newSorted = [];

    for (let i=0; i<this.sorted.length; i++) {
      const listDate = this.sorted[i].date.split(":");  // Year:Month:Date:....
      let isAuthYear = false;
      let isAuthMonth = false;
      let isAuthDay = false;

      // --- Sort With Year
      if (yearMin != null && yearMax != null) {
        if (yearMin >= parseInt(listDate[0]) && parseInt(listDate[0]) <= yearMax) isAuthYear = true;
      }
      else if (yearNow != null) {
        if (yearNow == parseInt(listDate[0])) isAuthYear = true;
      }
      else isAuthYear = true;

      // --- Sort With Month
      if (isAuthYear) {
        if (monthMin != null && monthMax != null) {
          if (monthMin >= parseInt(listDate[1]) && parseInt(listDate[1]) <= monthMax) isAuthMonth = true;
        }
        else if (monthNow != null) {
          if (monthNow == parseInt(listDate[1])) isAuthMonth = true;
        }
        else isAuthMonth = true;
      }

      // --- Sort With Day
      if (isAuthYear && isAuthMonth) {
        if (dayMin != null && dayMax != null) {
          if (dayMin >= parseInt(listDate[2]) && parseInt(listDate[2]) <= dayMax) isAuthDay = true;
        }
        else if (dayNow != null) {
          if (dayNow == parseInt(listDate[2])) isAuthDay = true;
        }
        else isAuthDay = true;
      }

      if (isAuthYear && isAuthMonth && isAuthDay) newSorted.push(this.sorted[i]);
    }

    this.sorted = newSorted;
  }

  public parseDate(date: string) : Date {
    if (!date) return new Date();
    const a = date.split(':');

    return new Date(parseInt(a[0]), parseInt(a[1]), parseInt(a[2]), parseInt(a[3]), parseInt(a[4]));
  }

  public totalFunds() : any {
    let total = 0;

    for (let i=0; i<this.sorted.length; i++)
      total += this.sorted[i].amount;

    return total;
  }

  private sort() {
    this.sorted = this.memberships;
    this.sortMembershipsWithPartners();
    this.sortMembershipsWithSouscriptors();
    this.sortMembershipsWithLevel();
    this.sortWithTypeOfSubscriptions();
    this.sortWithDate();
    this.refreshSouscriptorsFilter();
  }
  
  public selectChange(state: number) {
    let select = null;
  	if (state === 0) select = document.getElementById("typeSelect");
  	else if (state === 1) select = document.getElementById("partnerSelect");
  	else if (state === 2) select = document.getElementById("souscriptorSelect");
  	else select = document.getElementById("levelSelect");
  	
  	if (state === 0) this.typeFilterSelected = select.options[select.selectedIndex].value;
  	else if (state === 1) this.partnerFilterSelected = select.options[select.selectedIndex].value;
  	else if (state === 2) this.souscriptorFilterSelected = select.options[select.selectedIndex].value;
  	else this.levelFilterSelected = select.options[select.selectedIndex].value;
  	
  	this.sort();  // Sort
  }

  private getPartners() {
    this.resourceService.getResources('/partner/all').subscribe(
      data => {
        this.partnersFilter = data;
        console.log('dat', this.partnersFilter);
      },
      err => {}
    )
  }
}
