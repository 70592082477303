import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Level} from "../../../models/level";
import {FormGroup} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {Errors} from "../../../settings/function_errors";
import {ResourceService} from "../../../services/resource.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-list-sponsorships',
  templateUrl: './list-sponsorships.component.html',
  styleUrls: ['./list-sponsorships.component.css']
})
export class ListSponsorshipsComponent implements OnInit {
  @ViewChild('closemodal', {static: true}) closemodal;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  sponsors: any;
  baseUrl: string;
  detailsData = {
    id : 0,
    sponsorships :null,
    souscriptors: null,
    status: false
  };

  constructor(private resourceService: ResourceService,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.baseUrl = environment.baseUrl;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  public liste() {

    this.resourceService.getResources('/sponsorships/all').subscribe(data => {

      this.sponsors = data;
      console.log('sponsor', data);

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(sponsor){
    this.detailsData = {
        id : sponsor.id,
        sponsorships : sponsor.sponsorships,
        souscriptors: sponsor.souscriptors,
        status: sponsor.status
    };

    console.log(this.detailsData.id);
  }
}
