import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-gains-nature',
  templateUrl: './list-gains-nature.component.html',
  styleUrls: ['./list-gains-nature.component.css']
})
export class ListGainsNatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
