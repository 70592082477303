import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { SocketserviceService } from 'src/app/services/socketservice.service';
import { CustomValidators } from 'ng2-validation';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sign-in-user',
  templateUrl: './sign-in-user.component.html',
  styleUrls: ['./sign-in-user.component.scss', "../../../../assets/css/font-awesome.min.css"]
})
export class SignInUserComponent implements OnInit {
  private titleKey: string = "login-page";
  private subtitleKey: string = "login-page-desc";

  loginForm: FormGroup;
  submitted = false;
  baseurl: string;
  loading = false;
  // user: any = [];

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService,
              private title: Title, private translate: TranslateService) {
    this.title.setTitle(this.getTitle());
  }

  ngOnInit() {
    this.createForm();
    this.baseurl = environment.baseUrl;
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{9}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      password: [null, Validators.required]
    });
  }

  // "/^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/"
  // "/^(?:\d{10}|\w+@\w+\.\w{2,3})$/"

  get f() {
    return this.loginForm.controls;
  }

  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else { control.markAsTouched(); }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.markFormTouched(this.loginForm);

    if (this.loginForm.invalid) {
      this.submitted = false;
      return;
    }

    // const formValues: any = this.loginForm.getRawValue;
    const data = {
      password: sha256(this.loginForm.value['password']),
      username: this.loginForm.value['email']
    };

    // Trying to connect user
    this.loading = true;

    this.resourceService.getsaveResource('/admin/connect', data).subscribe((data) => {
      let user: any = [];
      user = data;
      this.loading = false;
      if (user === 'not connected') {
        this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
      } else if (!user) {
        this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
      } else {
        // Setting token access and session variables
        localStorage.setItem('token', user.access_token);
        sessionStorage.setItem('checksess', 'e-Fid@mlm0@AdmI');
        user = user.user;
        sessionStorage.setItem('isConnect', 'true');
        sessionStorage.setItem('userId',  user.id);
        sessionStorage.setItem('username',  user.firstname + ' ' + user.lastname);
        sessionStorage.setItem('avatar',  user.avatar);
        sessionStorage.setItem('email',  user.email);
        sessionStorage.setItem('phone',  user.phone);
        sessionStorage.setItem('role',  user.roles);
        sessionStorage.setItem('type',  user.type);
        sessionStorage.setItem('adress',  user.adress);
        sessionStorage.setItem('town',  user.town);
        sessionStorage.setItem('country',  user.country);
        this.toastr.success('Vous êtes Connecté !', 'Success User');
        this.router.navigate(['/admin/dashboard']);
      }
    }, err => {
      this.toastr.error(Errors(err), "Connection Fail! : Impossible d'etablir une connexion. Veuillez réessayer après quelques secondes ou contacter le service support.");
      console.log('error', err);
	  console.log(Errors(err));
    });
  }

  getTitle() {
    return this.translate.instant(this.titleKey);
  }

  getSubtitle() {
    return this.translate.instant(this.subtitleKey);
  }
}
