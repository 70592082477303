import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

import {Observable, Subject} from "rxjs/index";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  public host = environment.baseUrl;

  private dataSuject = new Subject<any>();

  
// no token
  httpOptions3 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'

    })
  };

  constructor(private http: HttpClient) { }

  public getResources(url: string) {
    let  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(this.host + url, httpOptions);
  }

  public getResource(url, id:number){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(this.host+url+"/"+id, httpOptions);
  }

  //----------------- sans header---------------------------
  public getResources_unsafe(url: string) {
    return this.http.get(this.host + url);
  }
  
  public getResource_unsafe(url, id:number){
    return this.http.get(this.host+url+"/"+id);
  }
  public updateResource_safely(url, data: any): Observable<any[]> {
    return this.http.post<any[]>(this.host + url, data);
  }

  public saveResource_unsafe(url, data: any): Observable<any[]> {
    return this.http.post<any[]>(this.host + url, data);
  }

  public createaccountResource(url, data: any): Observable<any[]> {
    return this.http.post<any[]>(this.host + url, data);
  }

  public getsaveResource(url, data: any): Observable<any[]> {
    return this.http.get<any[]>(this.host + url, { params: data });
  }

  // -----------------header httpOptions-----------------------------
  public getResourceByKeyword(url, mc:string, page:number, size:number){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(this.host+url+"?mc="+mc+"&page="+page+"&size="+size, httpOptions);
  }

  public deleteResource(url, id){
    let  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(this.host+url+"/"+id, httpOptions);
  }

  public getResourceParam(url, data: any): Observable<any[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get<any[]>(this.host + url + '/' + data, httpOptions);
  }

  public activateResource(url, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(this.host + url + "/" + id, httpOptions);
  }

  public suspendResource(url, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(this.host + url + "/" + id, httpOptions);
  }

  public deleteallResource(url) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.get(this.host + url, httpOptions);
  }
//-------------------------header httpOptions2----------------------

  public saveResource(url, data:any): Observable<any[]>{
    let httpOptions2 = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.post<any[]>(this.host+url, data,httpOptions2);
  }

  public updateResource(url, data: any): Observable<any[]>{
    let httpOptions2 = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
    return this.http.post<any[]>(this.host+url, data, httpOptions2);
  }


  sendData( data:any){
    this.dataSuject.next(data);
  }
  
  getData(): Observable<any>{
    return this.dataSuject.asObservable();
  }
  
  public postResource(url, data:any){
    let httpOptions2 = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('token')
      })
    };
     return this.http.post<any[]>(this.host+url, data, httpOptions2).toPromise();
    // return this.http.post<any[]>(this.host+url, data, httpOptions2);
  }

}
