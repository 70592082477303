import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ResourceService} from "../../../services/resource.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Errors} from "../../../settings/function_errors";
import {Level} from "../../../models/level";

@Component({
  selector: 'app-add-level',
  templateUrl: './add-level.component.html',
  styleUrls: ['./add-level.component.css']
})
export class AddLevelComponent implements OnInit {

  newLevel: Level;
  roleForm: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder, private resourceService:ResourceService, private router:Router, private toastr: ToastrService) {
    this.newLevel={} as Level;
  }

  // id?: any;
  // name: string;
  // date_created?: Date;
  ngOnInit() {

    this.createForm();
  }

  createForm() {
    this.roleForm = this.fb.group({
      name: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.roleForm.invalid ) {
      return;
    }
    else {
      let formValue = this.roleForm.value;

      this.newLevel.id = null;
      this.newLevel.name = formValue['name'];
      this.newLevel.date_created = new Date();

      // Save datas create role in database
      this.resourceService.saveResource('/level/create', this.newLevel).subscribe((data) => {

        console.log('data', data);
        this.roleForm.reset();
        this.toastr.success('Le niveau a été crée!', 'Sucess');
        this.router.navigate(['/admin/confirm/action']);

      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
        console.log(Errors(err));
      });

    }
  }

  get f1() {
    // console.log(this.roleForm.controls);
    return this.roleForm.controls;
  }

}
