import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from '../app.component';
import { LayoutSouscriptorComponent } from '../layout/souscriptor/layout-souscriptor/layout-souscriptor.component';
import { LayoutAdminComponent } from '../layout/admin/layout-admin/layout-admin.component';
import { LayoutSiteComponent } from '../layout/site/layout-site/layout-site.component';

/* Begin Import Site Pages */
import { HomeComponent } from '../pages/site/home/home.component';
import { ForgetPasswordComponent } from '../pages/site/forget-password/forget-password.component';
import { ConfirmComponent } from '../pages/site/confirm/confirm.component';
import {SignInSouscriptorComponent} from '../pages/site/sign-in-souscriptor/sign-in-souscriptor.component';
import {SignInUserComponent} from '../pages/site/sign-in-user/sign-in-user.component';
import {AboutUsComponent} from '../pages/site/about-us/about-us.component';
import {OurVisionComponent} from '../pages/site/our-vision/our-vision.component';
import {OurMissionComponent} from '../pages/site/our-mission/our-mission.component';
import {OurPartnersComponent} from '../pages/site/our-partners/our-partners.component';
import {NetworkMarketingComponent} from '../pages/site/network-marketing/network-marketing.component';
import {MyBusinessModelComponent} from '../pages/site/my-business-model/my-business-model.component';
import {FaqsComponent} from '../pages/site/faqs/faqs.component';
import {MySupportComponent} from '../pages/site/my-support/my-support.component';
import {LegalMentionsComponent} from '../pages/site/legal-mentions/legal-mentions.component';
import {GeneralTermsOfSaleComponent} from '../pages/site/general-terms-of-sale/general-terms-of-sale.component';
import {PresentationComponent} from '../pages/site/presentation/presentation.component';
import { ValidateEmailComponent } from '../pages/site/validate-email/validate-email.component';
import { ContactComponent } from '../pages/site/contact/contact.component';
/* End Import Site Pages */

/* Begin Import Admin Pages */
import { AdminDashboardComponent } from '../pages/admin/admin-dashboard/admin-dashboard.component';
import { EditProfileAdminComponent } from '../pages/admin/edit-profile-admin/edit-profile-admin.component';
import { EditPasswordAdminComponent } from '../pages/admin/edit-password-admin/edit-password-admin.component';
import { MyAccountAdminComponent } from '../pages/admin/my-account-admin/my-account-admin.component';
import { AddRoleComponent } from '../pages/admin/add-role/add-role.component';
import { ListRoleComponent } from '../pages/admin/list-role/list-role.component';
import { AddUserComponent } from '../pages/admin/add-user/add-user.component';
import { ListUserComponent } from '../pages/admin/list-user/list-user.component';
import {AddMenuComponent} from '../pages/admin/add-menu/add-menu.component';
import {ListMenuComponent} from '../pages/admin/list-menu/list-menu.component';
import {ConfirmActionComponent} from '../pages/admin/confirm-action/confirm-action.component';
import {AddGainComponent} from '../pages/admin/add-gain/add-gain.component';
import {ListGainComponent} from '../pages/admin/list-gain/list-gain.component';
import {AddLevelComponent} from '../pages/admin/add-level/add-level.component';
import {ListLevelComponent} from '../pages/admin/list-level/list-level.component';
import {AddPartnerComponent} from '../pages/admin/add-partner/add-partner.component';
import {ListPartnerComponent} from '../pages/admin/list-partner/list-partner.component';
import {AddSouscriptorComponent} from '../pages/admin/add-souscriptor/add-souscriptor.component';
import {ListSouscriptorComponent} from '../pages/admin/list-souscriptor/list-souscriptor.component';
import {ListGainsAllComponent} from '../pages/admin/list-gains-all/list-gains-all.component';
import {ListGainsCashComponent} from '../pages/admin/list-gains-cash/list-gains-cash.component';
import {ListGainsNatureComponent} from '../pages/admin/list-gains-nature/list-gains-nature.component';
import {ListMembershipsComponent} from '../pages/admin/list-memberships/list-memberships.component';
// import {ListMembershipsBasicComponent} from '../pages/admin/list-memberships-basic/list-memberships-basic.component';
// import {ListMembershipsClassicComponent} from '../pages/admin/list-memberships-classic/list-memberships-classic.component';
// import {ListMembershipsVipComponent} from '../pages/admin/list-memberships-vip/list-memberships-vip.component';
import {ListPartnersMembershipsComponent} from '../pages/admin/list-partners-memberships/list-partners-memberships.component';
// import {ListPartnersMembershipsClassicComponent} from '../pages/admin/list-partners-memberships-classic/list-partners-memberships-classic.component';
// import {ListPartnersMembershipsVipComponent} from '../pages/admin/list-partners-memberships-vip/list-partners-memberships-vip.component';
import {ListPartnersProductsBuyComponent} from '../pages/admin/list-partners-products-buy/list-partners-products-buy.component';
import { ComfirmSouscriptorComponent } from '../pages/admin/comfirm-souscriptor/comfirm-souscriptor.component';
import { AdminGuardService } from '../services/admin-guard.service';
/* End Import Admin Pages */

/* Begin Import Souscriptor Pages */
import {SouscriptorDashboardComponent} from '../pages/souscriptor/souscriptor-dashboard/souscriptor-dashboard.component';
import {MyGainsComponent} from '../pages/souscriptor/my-gains/my-gains.component';
import {MyMembershipsComponent} from '../pages/souscriptor/my-memberships/my-memberships.component';
import {MyNetworkComponent} from '../pages/souscriptor/my-network/my-network.component';
import {MyPositionsComponent} from '../pages/souscriptor/my-positions/my-positions.component';
import {MySouscriptionsComponent} from '../pages/souscriptor/my-souscriptions/my-souscriptions.component';
import {MySponsorshipsComponent} from '../pages/souscriptor/my-sponsorships/my-sponsorships.component';
import {MyMessagesComponent} from '../pages/souscriptor/my-messages/my-messages.component';
import {EditProfileSouscriptorComponent} from '../pages/souscriptor/edit-profile-souscriptor/edit-profile-souscriptor.component';
import {EditPasswordSouscriptorComponent} from '../pages/souscriptor/edit-password-souscriptor/edit-password-souscriptor.component';
import { SouscriptorGuardService } from '../services/souscriptor-guard.service';
import {ListSponsorshipsComponent} from '../pages/admin/list-sponsorships/list-sponsorships.component';
/* End Import Souscriptor Pages */

/* Begin Import General Pages */
/* End Import General Pages */

const SOUSCRIPTOR_ROUTES: Routes = [

  {
    path: 'dashboard',
    component: SouscriptorDashboardComponent,
  },

  {
    path: 'my-gains',
    component: MyGainsComponent,
  },

  {
    path: 'my-memberships',
    component: MyMembershipsComponent,
  },

  {
    path: 'my-network',
    component: MyNetworkComponent,
  },

  {
    path: 'my-positions',
    component: MyPositionsComponent,
  },

  {
    path: 'my-souscriptions',
    component: MySouscriptionsComponent,
  },

  {
    path: 'my-sponsorships',
    component: MySponsorshipsComponent,
  },

  {
    path: 'edit/profile',
    component: EditProfileSouscriptorComponent,
  },

  {
    path: 'edit/password',
    component: EditPasswordSouscriptorComponent,
  },

  {
    path: 'my-messages',
    component: MyMessagesComponent,
  }
 /*
  {
    path: 'new-message',
    component: SouscriptorNewMessageComponent,
  },
  {
    path: 'view-message',
    component: SouscriptorMessageViewComponent,
  },
  {
    path: 'show-message/:id_message/:idpat/:read',
    component: SouscriptorShowMessageComponent,
  }
  */

];

const SITES_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'about-us/presentation',
    component: PresentationComponent,
  },
  {
    path: 'about-us/our-vision',
    component: OurVisionComponent,
  },
  {
    path: 'about-us/our-mission',
    component: OurMissionComponent,
  },
  {
    path: 'about-us/our-partners',
    component: OurPartnersComponent,
  },
  {
    path: 'my-business/network-marketing',
    component: NetworkMarketingComponent,
  },
  {
    path: 'my-business/my-business-model',
    component: MyBusinessModelComponent,
  },
  {
    path: 'faqs',
    component: FaqsComponent,
  },
  {
    path: 'my-support',
    component: MySupportComponent,
  },
  {
    path: 'legal-mentions',
    component: LegalMentionsComponent,
  },
  {
    path: 'general-terms-of-sale',
    component: GeneralTermsOfSaleComponent,
  },
  {
    path: 'souscriptor/sign-in',
    component: SignInSouscriptorComponent,
  },
  {
    path: 'user/sign-in',
    component: SignInUserComponent,
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
  },
  {
    path: 'validate_email/:email',
    component: ValidateEmailComponent,
  }
  /* {
   path: 'login',
   component: LoginComponent,
 }, */
  // {
  //   path: 'contact',
  //   component: ContactComponent,
  // },
  // {
  //   path: 'admpro/user',
  //   component: EProUserComponent,
  // },
];


const ADMIN_ROUTES: Routes = [
  {
    path: 'dashboard',
    component: AdminDashboardComponent,
  },
  {
    path: 'edit/profile',
    component: EditProfileAdminComponent,
  },
  {
    path: 'edit/password',
    component: EditPasswordAdminComponent,
  },
  {
    path: 'my-account',
    component: MyAccountAdminComponent,
  },
  {
    path: 'add/gain',
    component: AddGainComponent,
  },
  {
    path: 'list/gains',
    component: ListGainComponent,
  },
  {
    path: 'list/gains/all',
    component: ListGainsAllComponent,
  },
  {
    path: 'list/gains/cash',
    component: ListGainsCashComponent,
  },
  {
    path: 'list/gains/products',
    component: ListGainsNatureComponent,
  },
  {
    path: 'add/level',
    component: AddLevelComponent,
  },
  {
    path: 'list/level',
    component: ListLevelComponent,
  },
  {
    path: 'add/partner',
    component: AddPartnerComponent,
  },
  {
    path: 'list/partners',
    component: ListPartnerComponent,
  },
  {
    path: 'list/partners/memberships/all',
    component: ListPartnersMembershipsComponent,
  },
  // {
  //   path: 'list/partners/memberships/classic',
  //   component: ListPartnersMembershipsClassicComponent,
  // },
  // {
  //   path: 'list/partners/memberships/vip',
  //   component: ListPartnersMembershipsVipComponent,
  // },
  {
    path: 'list/partners/products/buy',
    component: ListPartnersProductsBuyComponent,
  },
  {
    path: 'add/role',
    component: AddRoleComponent,
  },
  {
    path: 'list/role',
    component: ListRoleComponent,
  },
  {
    path: 'add/souscriptor',
    component: AddSouscriptorComponent,
  },
  {
    path: 'list/souscriptor',
    component: ListSouscriptorComponent,
  },
  {
    path: 'add/user',
    component: AddUserComponent,
  },
  {
    path: 'list/user',
    component: ListUserComponent,
  },
  {
    path: 'list/memberships/all',
    component: ListMembershipsComponent,
  },
  // {
  //   path: 'list/memberships/basic',
  //   component: ListMembershipsBasicComponent,
  // },
  // {
  //   path: 'list/memberships/classic',
  //   component: ListMembershipsClassicComponent,
  // },
  // {
  //   path: 'list/memberships/vip',
  //   component: ListMembershipsVipComponent,
  // },
  {
    path: 'list/sponsorships/all',
    component: ListSponsorshipsComponent,
  },
  {
    path: 'add/menu',
    component: AddMenuComponent,
  },
  {
    path: 'list/menu',
    component: ListMenuComponent,
  },
  {
    path: 'confirm/action',
    component: ConfirmActionComponent,
  },
  {
    path: 'confirm/souscriptor',
    component: ComfirmSouscriptorComponent,
  }
];


export const ROUTES: Routes = [
  {
    path: '',
    component: LayoutSiteComponent,
    children: SITES_ROUTES
  },
  {
    path: 'souscriptor',
    component: LayoutSouscriptorComponent,
    canActivate : [SouscriptorGuardService],
    children: SOUSCRIPTOR_ROUTES
  },
  {
    path: 'admin',
    component: LayoutAdminComponent,
	canActivate : [AdminGuardService],
    children: ADMIN_ROUTES
  },
 { path: '', redirectTo: '', pathMatch: 'full' },

  { path: '**', redirectTo: '' },
];


@NgModule({
  imports: [RouterModule],
  exports: [RouterModule]
})

/*
@NgModule({
  imports: [RouterModule.forRoot(Routes)],
  exports: [RouterModule],
  declarations: []
})
*/
export class AppRoutingModule { }
