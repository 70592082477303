import { Component, OnInit, OnDestroy } from '@angular/core';
import { Gain } from 'src/app/models/gain';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { environment } from 'src/environments/environment';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-list-gain',
  templateUrl: './list-gain.component.html',
  styleUrls: ['./list-gain.component.css']
})
export class ListGainComponent implements OnInit, OnDestroy {

  newGain: Gain;
  gainFormEdit: FormGroup;
  gains: any = [];
  level: any;
  partners: any = [];
  receiveData: any =[];
  // formData = new FormData();
  baseUrl: string;
  urls: any;
  public error;
  public success;
  dtTrigger: Subject <any> = new Subject();
  date: any;


  public detailsData =
    {
      id: null,
      cash: null,
      dietary_supplements: null,
      foods: null,
      vouchers: null,
      vehicle: null,
      travel: null,
      building_materials: null,
      type: null,
      level: {name: null},
      partner_gains: {amount: null},
      partners: {name: null, id: null},
      date_created: null,
    };

  baseurl: string;

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router: Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newGain = {} as Gain; }

  ngOnInit() {

    this.initFormEdit();
    this.baseurl = environment.baseUrl + '/public/';
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseurl + 'files/avatars/avatar.png';
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  initFormEdit() {
    this.gainFormEdit = this.formBuilder.group({
      checkbox: ['', Validators.required],
      type: null,
      cash: null,
      dietary_supplements: null,
      foods: null,
      vouchers: null,
      vehicle: null,
      travel: null,
      building_materials: null,
      level_id: [null, Validators.required],
    });
  }

  get f1() { return this.gainFormEdit.controls; }

  public liste() {

    this.resourceService.getResources('/gain/all').subscribe(data => {
      console.log(data);
      this.gains = data;
      this.dtTrigger.next();
    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(gain) {

    this.detailsData =
      {
        id: gain.id,
        cash: gain.cash,
        dietary_supplements: gain.dietary_supplements,
        foods: gain.foods,
        vouchers: gain.vouchers,
        vehicle: gain.vehicle,
        travel: gain.travel,
        building_materials: gain.building_materials,
        type: gain.type,
        level: gain.level,
        partner_gains: gain.partner_gains,
        partners: gain.partners,
        date_created: gain.date_created
      };
      
     console.log(this.detailsData.level);
  }

  public onEdit(gain) {
    this.detailsData =
      {
        id: gain.id,
        cash: gain.cash,
        dietary_supplements: gain.dietary_supplements,
        foods: gain.foods,
        vouchers: gain.vouchers,
        vehicle: gain.vehicle,
        travel: gain.travel,
        building_materials: gain.building_materials,
        type: gain.type,
        level: gain.level,
        partner_gains: gain.partner_gains,
        partners: gain.partners,
        date_created: gain.date_created
      };
    this.gainFormEdit.patchValue({
      checkbox: gain.checkbox,
      id: gain.id,
      cash: gain.cash,
      dietary_supplements: gain.dietary_supplements,
      foods: gain.foods,
      vouchers: gain.vouchers,
      vehicle: gain.vehicle,
      travel: gain.travel,
      building_materials: gain.building_materials,
      level_id: gain.level_id,
      date_created: gain.date_created
    });
  }


  public updateGain(){
    if (this.gainFormEdit.invalid) {
      return;
    }

    let formData = new FormData();
    const formValue = this.gainFormEdit.value;
    formData.append('cash', formValue['cash']);
    formData.append('dietary_supplements', formValue['dietary_supplements']);
    formData.append('foods', formValue['foods']);
    formData.append('vouchers', formValue['vouchers']);
    formData.append('vehicle', formValue['vehicle']);
    formData.append('travel', formValue['travel']);
    formData.append('building_materials', formValue['building_materials']);


    this.resourceService.updateResource('/gain/update', formData).subscribe(data => {

      console.log('data', data);
      this.liste();
      this.resetForm(this.gainFormEdit);
      this.toastr.success('Les données du gain ont été mis à jour!', 'Sucess');
      this.router.navigate(['/admin/list/gain']);

    }, error => {
      if(error.status == 500 || error.status == 503){
        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
      if(error.status == 400){
        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
    });
  }

  public resetForm(form){
    form.reset();
  }

  public  deleteGain(event: MouseEvent, gain) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('/gain/delete/one', gain.id).subscribe(data => {
          this.gains = data;
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/gain/delete/all').subscribe(data => {
          this.gains = data;
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }



}
