import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Partner } from 'src/app/models/partner';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
import { DataTableDirective } from 'angular-datatables';
// import {Shedule} from '../../../models/schedule';
declare var swal: any;

@Component({
  selector: 'app-list-partner',
  templateUrl: './list-partner.component.html',
  styleUrls: ['./list-partner.component.css']
})
export class ListPartnerComponent implements OnInit, OnDestroy, AfterViewInit {

  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();
  
  newPartner: Partner;
  partnerFormEdit: FormGroup;
  partners: any = [];
  //roles: any = [];
  // formData = new FormData();
  fileData: File = null;
  baseUrl: string;
  urls: any;
  url: string = '/partner';
  public error;
  public success;
  pays: any;
  date: any;

  public detailsData =
    {
      id: null,
      name: '',
      postalcode: '',
      phone: '',
      adress: '',
      email: '',
      town: '',
      country: '',
      logo: ''
    };

  baseurl: string;

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router: Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newPartner = {} as Partner; }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnInit() {
    this.initFormEdit();
    this.baseurl = environment.baseUrl + '/public/';
    this.baseUrl = environment.baseUrl;
    this.urls = environment.baseUrlImage + '/files/avatars/avatar.png';
    this.pays = paysdata;
  } 


  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }
  

  initFormEdit() {
    this.partnerFormEdit = this.formBuilder.group({
      checkbox: ['', Validators.required],
      name: ['', Validators.required],
      adress: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: [null, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
    });
  }

  get f1() { return this.partnerFormEdit.controls; }

  public liste() {

    this.resourceService.getResources('/partner/all').subscribe(
      data => {
        console.log(data)
        this.partners = data;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, error => {
        if(error.status==500 || error.status==503){
          this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
      }
    );
  }

  public onView(partner) {

    this.detailsData =
      {
        id: partner.id,
        name: partner.name,
        phone: partner.phone,
        email: partner.email,
        adress: partner.adress,
        postalcode: partner.postalcode,
        town: partner.town,
        country: partner.country,
        logo: this.baseUrl + 'public' + partner.logo
      };
  }

  public onEdit(partner) {
    this.partnerFormEdit.patchValue({
      checkbox: partner.checkbox,
      id: partner.id,
      name: partner.name,
      phone: partner.phone,
      email: partner.email,
      adress: partner.adress,
      postalcode: partner.postalcode,
      town: partner.town,
      country: partner.country,
	    logo: this.baseUrl + 'public' + partner.logo,
    });
  }


  public updatePartner(){
    if (this.partnerFormEdit.invalid) {
      return;
    }

    this.date = new Date().toDateString();
    let formData = new FormData();
    const formValue = this.partnerFormEdit.value;
    formData.append('id', formValue['id']);
    formData.append('name', formValue['name']);
    formData.append('email', formValue['email']);
    formData.append('phone', formValue['phone']);
    formData.append('adress', formValue['adress']);
    formData.append('postalcode', formValue['postalcode']);
    formData.append('town', formValue['town']);
    formData.append('country', formValue['country']);

    if(this.fileData){
      formData.append('logo', this.fileData );
    }

    this.resourceService.updateResource('/partner/update', formData).subscribe(data => {

      console.log('data', data);
      this.liste();
      this.resetForm(this.partnerFormEdit);
      this.toastr.success('Les données du partenaire ont été mis à jour!', 'Sucess');

    }, error => {
      if(error.status == 500 || error.status == 503){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
      if(error.status == 400){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
    });
  }

  public onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData );
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {
    }
  }


  public resetForm(form){
    form.reset();
  }


  public  deletePartner(event: MouseEvent, partner) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('/partner/delete/one', partner.id).subscribe(
          data => {
            this.liste();
            this.toastr.success('Your data has been successfully deleted!','Deleted!');
          }, err => {
            this.toastr.error(Errors(err), 'Fail to delete the data, \n');
            console.log('erreur', err);
            console.log(Errors(err));
          }
        );
      }
    });
  }

  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/partner/delete/all').subscribe(data => {
          this.liste()
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

}