import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {ResourceService} from "../../../services/resource.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Errors} from "../../../settings/function_errors";
import paysdata from 'src/app/pays.json';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-add-souscriptor',
  templateUrl: './add-souscriptor.component.html',
  styleUrls: ['./add-souscriptor.component.css']
})
export class AddSouscriptorComponent implements OnInit {
  souscriptorForm: FormGroup;
  submitted = false;
  country: any;
  fileData: File = null;
  urls: any = "assets/images/xs/user.png";
  baseUrl: string;
  paymentMode = [
    {
      'id': 0,
      'name': 'Chèque'
    },
    {
      'id': 1,
      'name': 'Espèces'
    },
    {
      'id': 2,
      'name': 'Virement Bancaire'
    },
    {
      'id': 3,
      'name': 'Transfert Mobile'
    }
  ];
  
  public datainvoice:any;

  constructor(private fb: FormBuilder, private resourceService:ResourceService, private router:Router, private toastr: ToastrService) {
	this.datainvoice = {};
  }

  ngOnInit() {
    this.country = paysdata;
    this.createForm();
    this.baseUrl = environment.baseUrl;
    this.urls = environment.baseUrlImage + '/files/avatars/avatar.png';
  }

  createForm() {
    this.souscriptorForm = this.fb.group({
      checkbox: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      birthdate: [''],
      matricule: [''],
      adress: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', Validators.required],  // Validators.pattern("^[+0-9 ]{6,}$")
      cni_passport: ['', Validators.required],
      delivery_date: ['', Validators.required],
      delivery_location: ['', Validators.required],
      paymentMode: ['', Validators.required],
      basic: [''],
      classic: [''],
      vip: [''],
      transactionNumber: ['']
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.souscriptorForm.invalid || !this.souscriptorForm.value['checkbox']) {
      return;
    }
    else if (!this.souscriptorForm.value['basic'] && !this.souscriptorForm.value['classic'] && !this.souscriptorForm.value['vip']) {
      console.log('block', this.souscriptorForm.value['basic'], typeof this.souscriptorForm.value['classic']);
      return;
    }
    else if (this.souscriptorForm.value['paymentMode'] !== this.paymentMode[1].name && !this.souscriptorForm.value['transactionNumber']) {
      // Transaction number requires because the payment isn't cash face
      console.log('not cash flow face start');
      return;
    }
    else {
      let formValue = this.souscriptorForm.value;
      let formData = new FormData();

      formData.append('firstname', formValue['firstname']);
      formData.append('lastname', formValue['lastname']);
      formData.append('birthdate', formValue['birthdate']);
      formData.append('matricule', formValue['matricule']);
      formData.append('adress', formValue['adress']);
      formData.append('postalcode', formValue['postalcode']);
      formData.append('town', formValue['town']);
      formData.append('country', formValue['country']);
      formData.append('email', formValue['email']);
      formData.append('phone', formValue['phone']);
      formData.append('cni_passport', formValue['cni_passport']);
      formData.append('delivery_date', formValue['delivery_date']);
      formData.append('delivery_location', formValue['delivery_location']);
      formData.append('status', "0");
      formData.append('deleted', "0");
      formData.append('connected', "0");
      formData.append('date_created', (new Date()).toDateString());

      formData.append('basic', this.souscriptorForm.value['basic'] || false);
      formData.append('classic', this.souscriptorForm.value['classic'] || false);
      formData.append('vip', this.souscriptorForm.value['vip'] || false);
      formData.append('transaction_number', formValue['transactionNumber']);
      formData.append('payment_mode', formValue['paymentMode']);

      // Add picture
      if(this.fileData){
        formData.append('avatar', this.fileData);
      }
	  
	 
      // Save data create role in database
      this.resourceService.saveResource('/souscriptor/create', formData).subscribe((data) => {
		let res: any = data;
        console.log('res', res);
		
		this.datainvoice.pswd = res.pswd;
		this.datainvoice.id = res.souscriptor.id;
		this.datainvoice.matricule = res.souscriptor.matricule;
		this.datainvoice.firstname = res.souscriptor.firstname;
		this.datainvoice.lastname = res.souscriptor.lastname;
		this.datainvoice.adress = res.souscriptor.adress;
		this.datainvoice.town = res.souscriptor.town;
		this.datainvoice.country = res.souscriptor.country;
		this.datainvoice.postalcode = res.souscriptor.postalcode;
		this.datainvoice.phone = res.souscriptor.phone;
		this.datainvoice.email = res.souscriptor.email;
		this.datainvoice.cni_passport = res.souscriptor.cni_passport;
		this.datainvoice.delivery_date = res.souscriptor.delivery_date;
		this.datainvoice.delivery_location = res.souscriptor.delivery_location;
		this.datainvoice.date_created = res.souscriptor.date_created;
		this.datainvoice.payment_mode = formValue['paymentMode'];
		this.datainvoice.transaction_number = formValue['transactionNumber'];
		
		
		
		if (this.souscriptorForm.value['basic']){
			this.datainvoice.amount1 = 3000;
			this.datainvoice.description1 = 'Souscription au Plan BASIC';
		} else {
			this.datainvoice.amount1 = 0;
			this.datainvoice.description1 = '';
		}
		
		if (this.souscriptorForm.value['classic']){
			this.datainvoice.amount2 = 30000;
			this.datainvoice.description2 = 'Souscription au Plan CLASSIC';
		} else {
			this.datainvoice.amount2 = 0;
			this.datainvoice.description2 = '';
		}
		
		if (this.souscriptorForm.value['vip']){
			this.datainvoice.amount3 = 300000;
			this.datainvoice.description3 = 'Souscription au Plan VIP';
		} else {
			this.datainvoice.amount3 = 0;
			this.datainvoice.description3 = '';
		}
		
		if ((this.datainvoice.amount1 !== 0 ) || (this.datainvoice.amount2 !== 0) || (this.datainvoice.amount3 !== 0)) {
			this.datainvoice.total = this.datainvoice.amount1 + this.datainvoice.amount2 + this.datainvoice.amount3;
		}
		
        this.toastr.success('Le souscripteur a été crée!', 'Sucess');
		sessionStorage.setItem('datainvoice', JSON.stringify(this.datainvoice));
		this.router.navigate(['/admin/confirm/souscriptor']);
		this.souscriptorForm.reset();

      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
        console.log(Errors(err));
      });

    }
  }

  get f1() {
    // console.log(this.roleForm.controls);
    return this.souscriptorForm.controls;
  }

  onSelectFile(events, f: NgForm) {
    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    }
    else {}
  }
}
