import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Subject } from 'rxjs';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Invoice } from 'src/app/models/invoice';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comfirm-souscriptor',
  templateUrl: './comfirm-souscriptor.component.html',
  styleUrls: ['./comfirm-souscriptor.component.css']
})
export class ComfirmSouscriptorComponent implements OnInit {

  public invoice:any;
  public datainvoice:any;
  public userId:any;
  // public idpay:any;

  constructor(private router: Router, private route: ActivatedRoute, private resourceService:ResourceService, private invoiceService:InvoiceService, public datepipe:DatePipe, private translate:TranslateService) {
		// this.invoice = {} as Invoice;
		this.invoice = {} ;
	}

  ngOnInit() {
	  this.datainvoice = JSON.parse(sessionStorage.getItem('datainvoice'));
  }


  public downloadBill(inv) { 
	let date = new Date();
	let myDate = '' + date.getFullYear();
	let myMonth = 1 + date.getMonth();
	this.invoice.number = 'FA00' + inv.id + myMonth + myDate.substring(myDate.length - 2, myDate.length);
	this.invoice.pswd = inv.pswd;
	this.invoice.matricule = inv.matricule;
	this.invoice.firstname = inv.firstname;
	this.invoice.lastname = inv.lastname;
	this.invoice.adress = inv.adress;
	this.invoice.town = inv.town;
	this.invoice.country = inv.country;
	this.invoice.postalcode = inv.postalcode;
	this.invoice.phone = inv.phone;
	this.invoice.email = inv.email;
	this.invoice.cni_passport = inv.cni_passport;
	this.invoice.delivery_date = this.datepipe.transform(inv.delivery_date,'dd/MM/yyyy');
	this.invoice.delivery_location = inv.delivery_location;
	this.invoice.date_created = this.datepipe.transform(inv.date_created,'dd/MM/yyyy');
	this.invoice.payment_mode = inv.payment_mode;
	this.invoice.transaction_number = inv.transaction_number;
	this.invoice.amount1 = inv.amount1;
	this.invoice.amount2 = inv.amount2;
	this.invoice.amount3 = inv.amount3;
	this.invoice.total = inv.total;
	this.invoice.description1 = inv.description1;
	this.invoice.description2 = inv.description2;
	this.invoice.description3 = inv.description3;
	//this.invoice.description = inv.description?inv.description:this.translate.instant('not_motif');
	this.invoiceService.downloadBill(this.invoice);
	sessionStorage.removeItem('datainvoice');
  }

}





