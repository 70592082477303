import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-partners-products-buy',
  templateUrl: './list-partners-products-buy.component.html',
  styleUrls: ['./list-partners-products-buy.component.css']
})
export class ListPartnersProductsBuyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
