import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
import {Level} from "../../../models/level";
declare var swal: any;

@Component({
  selector: 'app-list-level',
  templateUrl: './list-level.component.html',
  styleUrls: ['./list-level.component.css']
})
export class ListLevelComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closemodal', {static: true}) closemodal;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  newLevel:Level;
  levelFormEdit: FormGroup;
  levels: any = [];
  public error;
  public success;
  submitted = false;

  public detailsData =
    {
      id : null,
      name : ''
    };
  baseUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router:Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newLevel={} as Level; }

  ngOnInit() {

    this.baseUrl = environment.baseUrl;
    this.initFormEdit();
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  initFormEdit() {
    this.levelFormEdit = this.formBuilder.group({
      id: '',
      name: ['', Validators.required]
    });
  }

  get e() { return this.levelFormEdit.controls; }

  public liste() {

    this.resourceService.getResources('/level/all').subscribe(data => {

      this.levels = data;

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(level){

    this.detailsData =
      {
        id : level.id,
        name : level.name
      };

    console.log(this.detailsData.id);
  }

  public onEdit(level) {
    this.levelFormEdit.patchValue({
      id: level.id,
      name: level.name
    });
  }

  public updateRole(){
    this.submitted = true;

    if (this.levelFormEdit.invalid) {
      return;
    }

    const formValue = this.levelFormEdit.value;
    this.newLevel.id = formValue['id'];
    this.newLevel.name = formValue['name'];

    this.resourceService.updateResource('/level/update/' + formValue['id'], this.newLevel).subscribe(data => {
      // this.roleFormEdit.reset();
      this.resetForm(this.levelFormEdit);
      this.closemodal.nativeElement.click();
      this.liste();
      this.toastr.success('Le role a été mis à jour!', 'Sucess');

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
      if(error.status==400){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public resetForm(form){
    form.reset();
  }

  public deleteRole(event: MouseEvent, level) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('/level/delete/one', level.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/level/delete/all').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }
}
