import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppComponent } from '../app.component';
import { ArchwizardModule } from 'angular-archwizard';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';

import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { EditProfileAdminComponent } from './admin/edit-profile-admin/edit-profile-admin.component';
import { EditPasswordAdminComponent } from './admin/edit-password-admin/edit-password-admin.component';
import { MyAccountAdminComponent } from './admin/my-account-admin/my-account-admin.component';

import { AddRoleComponent } from './admin/add-role/add-role.component';
import { ListRoleComponent } from './admin/list-role/list-role.component';

import { AddUserComponent } from './admin/add-user/add-user.component';
import { ListUserComponent } from './admin/list-user/list-user.component';

import { HomeComponent } from './site/home/home.component';

import { ForgetPasswordComponent } from './site/forget-password/forget-password.component';
import { ContactComponent } from './site/contact/contact.component';
import { AboutUsComponent } from './site/about-us/about-us.component';
import { OurMissionComponent } from './site/our-mission/our-mission.component';
import { OurPartnersComponent } from './site/our-partners/our-partners.component';
import { LegalMentionsComponent } from './site/legal-mentions/legal-mentions.component';

import { MessagesComponent } from './general/messages/messages.component';

import { ConfirmComponent } from './site/confirm/confirm.component';
import { ConfirmationDialogService} from '../ui/notifications/notification.service';
import { NotificationsComponent } from '../ui/notifications/notifications.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TruncatePipesModule } from 'angular-truncate-pipes';
import { ResourceService } from '../services/resource.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { ValidateEmailComponent } from './site/validate-email/validate-email.component';

import { DataTablesModule } from 'angular-datatables';

import { AgmCoreModule } from '@agm/core';
import { DatetimepickerService } from '../services/datetimepicker.service';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { NgxPaginationModule } from 'ngx-pagination';

registerLocaleData(localeFr);
registerLocaleData(localeEs);
import { InvoiceService } from '../services/invoice.service';
import { AddMenuComponent } from './admin/add-menu/add-menu.component';
import { ListMenuComponent } from './admin/list-menu/list-menu.component';
import { AdminGuardService } from '../services/admin-guard.service';
import { SouscriptorGuardService } from '../services/souscriptor-guard.service';
import { ConfirmActionComponent } from './admin/confirm-action/confirm-action.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { NgxLoadingModule } from 'ngx-loading';


// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { SocketserviceService } from '../services/socketservice.service';
import { MediastreamService } from '../services/mediastream.service';

//import { MomentModule } from 'ngx-moment';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SocketService } from '../services/socket.service';
import { SouscriptorDashboardComponent } from './souscriptor/souscriptor-dashboard/souscriptor-dashboard.component';
import { EditPasswordSouscriptorComponent } from './souscriptor/edit-password-souscriptor/edit-password-souscriptor.component';
import { EditProfileSouscriptorComponent } from './souscriptor/edit-profile-souscriptor/edit-profile-souscriptor.component';
import { MyPositionsComponent } from './souscriptor/my-positions/my-positions.component';
import { MyGainsComponent } from './souscriptor/my-gains/my-gains.component';
import { MySouscriptionsComponent } from './souscriptor/my-souscriptions/my-souscriptions.component';
import { MyMembershipsComponent } from './souscriptor/my-memberships/my-memberships.component';
import { MySponsorshipsComponent } from './souscriptor/my-sponsorships/my-sponsorships.component';
import { MyMessagesComponent } from './souscriptor/my-messages/my-messages.component';
import { MyNetworkComponent } from './souscriptor/my-network/my-network.component';
import { AddSouscriptorComponent } from './admin/add-souscriptor/add-souscriptor.component';
import { ListSouscriptorComponent } from './admin/list-souscriptor/list-souscriptor.component';
import { AddPartnerComponent } from './admin/add-partner/add-partner.component';
import { ListPartnerComponent } from './admin/list-partner/list-partner.component';
import { AddLevelComponent } from './admin/add-level/add-level.component';
import { ListLevelComponent } from './admin/list-level/list-level.component';
import { AddGainComponent } from './admin/add-gain/add-gain.component';
import { ListGainComponent } from './admin/list-gain/list-gain.component';
import { ListMembershipsComponent } from './admin/list-memberships/list-memberships.component';
// import { ListMembershipsBasicComponent } from './admin/list-memberships-basic/list-memberships-basic.component';
// import { ListMembershipsClassicComponent } from './admin/list-memberships-classic/list-memberships-classic.component';
// import { ListMembershipsVipComponent } from './admin/list-memberships-vip/list-memberships-vip.component';
// import { ListPartnersMembershipsVipComponent } from './admin/list-partners-memberships-vip/list-partners-memberships-vip.component';
// import { ListPartnersMembershipsClassicComponent } from './admin/list-partners-memberships-classic/list-partners-memberships-classic.component';
import { ListPartnersMembershipsComponent } from './admin/list-partners-memberships/list-partners-memberships.component';
import { ListSponsorshipsComponent } from './admin/list-sponsorships/list-sponsorships.component';
import { ListGainsCashComponent } from './admin/list-gains-cash/list-gains-cash.component';
import { ListGainsNatureComponent } from './admin/list-gains-nature/list-gains-nature.component';
import { ListGainsAllComponent } from './admin/list-gains-all/list-gains-all.component';
import { ListPartnersProductsBuyComponent } from './admin/list-partners-products-buy/list-partners-products-buy.component';
import { OurVisionComponent } from './site/our-vision/our-vision.component';
import { NetworkMarketingComponent } from './site/network-marketing/network-marketing.component';
import { MyBusinessModelComponent } from './site/my-business-model/my-business-model.component';
import { FaqsComponent } from './site/faqs/faqs.component';
import { MySupportComponent } from './site/my-support/my-support.component';
import { SignInSouscriptorComponent } from './site/sign-in-souscriptor/sign-in-souscriptor.component';
import { SignInUserComponent } from './site/sign-in-user/sign-in-user.component';
import { GeneralTermsOfSaleComponent } from './site/general-terms-of-sale/general-terms-of-sale.component';
import { PresentationComponent } from './site/presentation/presentation.component';
import {LayoutModule} from "../layout/layout.module";
import { ComfirmSouscriptorComponent } from './admin/comfirm-souscriptor/comfirm-souscriptor.component';
// const config: SocketIoConfig = { url:environment.socketUrl, options: {} };
export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  declarations: [
    AppComponent,
    MessagesComponent,

    AdminDashboardComponent,
    EditProfileAdminComponent,
    EditPasswordAdminComponent,
    MyAccountAdminComponent,

    AddRoleComponent,
    ListRoleComponent,
    AddUserComponent,
    ListUserComponent,

    HomeComponent,
    ContactComponent,
    AboutUsComponent,
    LegalMentionsComponent,

    OurMissionComponent,
    OurPartnersComponent,
    ForgetPasswordComponent,
    ConfirmComponent,
    NotificationsComponent,
    ValidateEmailComponent,
    AddMenuComponent,
    ListMenuComponent,
    ConfirmActionComponent,
    SouscriptorDashboardComponent,
    EditPasswordSouscriptorComponent,
    EditProfileSouscriptorComponent,
    MyPositionsComponent,
    MyGainsComponent,
    MySouscriptionsComponent,
    MyMembershipsComponent,
    MySponsorshipsComponent,
    MyMessagesComponent,
    MyNetworkComponent,
    AddSouscriptorComponent,
    ListSouscriptorComponent,
    AddPartnerComponent,
    ListPartnerComponent,
    AddLevelComponent,
    ListLevelComponent,
    AddGainComponent,
    ListGainComponent,
    ListMembershipsComponent,
    // ListMembershipsBasicComponent,
    // ListMembershipsClassicComponent,
    // ListMembershipsVipComponent,
    // ListPartnersMembershipsVipComponent,
    // ListPartnersMembershipsClassicComponent,
    ListPartnersMembershipsComponent,
    ListSponsorshipsComponent,
    ListGainsCashComponent,
    ListGainsNatureComponent,
    ListGainsAllComponent,
    ListPartnersProductsBuyComponent,
    OurVisionComponent,
    NetworkMarketingComponent,
    MyBusinessModelComponent,
    FaqsComponent,
    MySupportComponent,
    SignInSouscriptorComponent,
    SignInUserComponent,
    GeneralTermsOfSaleComponent,
    PresentationComponent,
    ComfirmSouscriptorComponent
],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        CustomFormsModule,
        ReactiveFormsModule,
        ArchwizardModule,
        NgbModule,
        TruncatePipesModule,
        BrowserAnimationsModule,
        NgxLoadingModule.forRoot({}),
        ToastrModule.forRoot({timeOut: 6000}),
        DataTablesModule,
        //  MomentModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDMcpS6XuqtUoWfNhB_CuhnGmKVfdjBV20',
            libraries: ['places']
        }),
        // NgxDatetimeRangePickerModule.forRoot()
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        Ng2FlatpickrModule,
        NgxPaginationModule,
        NgSelectModule,

        HttpClientModule,
        Ng2ImgMaxModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LayoutModule,
        // SocketIoModule.forRoot(config)
    ],

  providers: [MediastreamService, SocketserviceService, DatePipe, SocketService, ConfirmationDialogService, ResourceService, DatetimepickerService, InvoiceService, SouscriptorGuardService, AdminGuardService],
  entryComponents: [NotificationsComponent],
})
export class PagesModule {
}
