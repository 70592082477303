import { Injectable } from '@angular/core';
import { Invoice } from '../models/invoice';
import jsPDF from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})

export class InvoiceService {
	
	public documentDefinition:any;
	
	constructor(private translate:TranslateService) { }
	
	getBase64ImageFromURL(url) {
		
		return new Promise((resolve, reject) => {
			
		  var img = new Image();
		  
		  img.setAttribute("crossOrigin", "anonymous");
		  
		  img.onload = () => {
			var canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;
			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);
			var dataURL = canvas.toDataURL("image/png");
			resolve(dataURL);
		  };
		  
		  img.onerror = error => {
			reject(error);
		  };
		  
		  img.src = url;
		  
		});
  }

  async downloadBill(invoice:any ) {
	
	this.documentDefinition = { 
    
      content: [
        
			// Header
			{
				columns: [
					{         
						image:await this.getBase64ImageFromURL("../../assets/images/logo.png"),
						/* image:await this.getBase64ImageFromURL("src/assets/images/logo.jpg"), */
						width: 80,
						height: 80
					},
						
					[
						{
							text: this.translate.instant('bill1'), 
							style: 'invoiceTitle',
							width: '*'
						},
						{
						  stack: [
							   {
								   columns: [
										{
											text: this.translate.instant('bill')+' N°', 
											style:'invoiceSubTitle',
											width: '*'
											
										}, 
										{
											text: invoice.number,
											style:'invoiceSubValue',
											width: 100
											
										}
									]
							   },
							   {
								   columns: [
									   {
										   text:this.translate.instant('date'),
										   style:'invoiceSubTitle',
										   width: '*'
									   }, 
									   {
										   text:invoice.date_created,
										   style:'invoiceSubValue',
										   width: 100
									   }
									]
							   },
							   {
								   columns: [
									   {
										   text:'Due Date',
										   style:'invoiceSubTitle',
										   width: '*'
									   }, 
									   {
										   text:invoice.date_created,
										   style:'invoiceSubValue',
										   width: 100
									   }
									]
							   },
						   ]
						}
					],
				],
			},
			
			// Line breaks
			'\n\n',
			
			// Billing Headers
			/* {
				columns: [
					{
						text:  this.translate.instant('bill_from'),
						style:'invoiceBillingTitle',
						
					},
					{
						text:  this.translate.get('bill_to'),
						style:'invoiceBillingTitle',
						
					},
				]
			}, */
			
			// Billing Details
			{
				columns: [
					{
						text: 'FIDELE MLM',
						style: 'invoiceBillingTitle'
					},
					{
						text: 'M./Mme.  ' + invoice.firstname + ' ' + invoice.lastname,
						style: 'invoiceBillingTitle'
					},
				]
			},

			// Billing Address Title
			{
				columns: [
					{
						text:  this.translate.instant('head_office'),
						style: 'invoiceBillingAddressTitle'
					},
					{
						text:  this.translate.instant('addr'),
						style: 'invoiceBillingAddressTitle'
					},
				]
			},
			
			// Billing tel
			{
				columns: [
					{
						text: this.translate.instant('phone')+'.: (+237) 653 66 08 91',
						style: 'invoiceBillingAddress'
					},
					{
						text: this.translate.instant('phone')+'.: (' + invoice.postalcode + ') ' + invoice.phone,
						style: 'invoiceBillingAddress'
					},
				]
			},

			// Billing email
			{
				  columns: [
					  {
						  text:  this.translate.instant('email')+' : contact@fidele-mlm.com',
						  style: 'invoiceBillingAddress'
					  },
					  {
						  text:  this.translate.instant('email')+' : '+ invoice.email,
						  style: 'invoiceBillingAddress'
					  },
				  ]
			},
			
			// Billing Address
			{
				columns: [
					{
						text: 'Yaoundé Cameroun, face pharmacie NSAM, BP: 5933',
						style: 'invoiceBillingAddress'
					},
					{
						text: invoice.adress + ', ' + invoice.town + ' - ' + invoice.country,
						style: 'invoiceBillingAddress'
					},
				]
			},
			
			// Billing matricule souscriptor
			{
				  columns: [
					  {
						  text:  '',
						  style: 'invoiceBillingAddress'
					  },
					  {
						  text:  this.translate.instant('matricule')+' : '+ invoice.matricule,
						  style: 'invoiceBillingAddress'
					  },
				  ]
			},
			
			// Billing password souscriptor
			{
				  columns: [
					  {
						  text:  '',
						  style: 'invoiceBillingAddress'
					  },
					  {
						  text:  this.translate.instant('password')+' : '+ invoice.pswd,
						  style: 'invoiceBillingAddress'
					  },
				  ]
			},
			  
			// Line breaks
			'\n\n\n\n',
			
			// Items
			{
				table: {
				  // headers are automatically repeated if the table spans over multiple pages
				  // you can declare how many rows should be treated as headers
				  
				  headerRows: 1,
				  widths: [ 300, 'auto', 'auto', 100 ],
		  
				  body: [
					// Table Header
					[ 
						{
							text:this.translate.instant('descrip'),
							style: 'itemsHeader'
						}, 
						{
							text: this.translate.instant('pu'),
							style: [ 'itemsHeader', 'center']
						}, 
						{
							text: this.translate.instant('qte'),
							style: [ 'itemsHeader', 'center']
						}, 
						{
							text: this.translate.instant('amount'),
							style: [ 'itemsHeader', 'center']
						},
					  
					],
					
					// Items
					// Item 1
					[ 
						{
							text: invoice.description1,
							style:'itemSubTitle'
						}, 
						{
							text:invoice.amount1,
							style:['itemSubTitle', 'center']
						}, 
						{
							text:'1',
							style:['itemSubTitle', 'center']
						},  
						{
						  text:invoice.amount1 +' XAF',
						  style:['itemTotal', 'right']
						},
					],
					
					// Item 2
					[ 
						{
							text: invoice.description2,
							style:'itemSubTitle'
						}, 
						{
							text:invoice.amount2,
							style:['itemSubTitle', 'center']
						}, 
						{
							text:'1',
							style:['itemSubTitle', 'center']
						},  
						{
						  text:invoice.amount2 +' XAF',
						  style:['itemTotal', 'right']
						},
					],
					
					// Item 3
					[ 
						{
							text: invoice.description3,
							style:'itemSubTitle'
						}, 
						{
							text:invoice.amount3,
							style:['itemSubTitle', 'center']
						}, 
						{
							text:'1',
							style:['itemSubTitle', 'center']
						},  
						{
						  text:invoice.amount3 +' XAF',
						  style:['itemTotal', 'right']
						},
					],
				
					// END Items
					
				  ]
				}, // table
			  //  layout: 'lightHorizontalLines'
			},
			
			// Table Footer
			{
				table: {
					widths: ['*', 100],
					body: [
						[ 
							{
								text:this.translate.instant('total'),
								style: [ 'itemsFooterTotalTitle', 'right']
							}, 
							{
								text:invoice.total + ' XAF',
								style: [ 'itemsFooterTotalValue', 'center']
							}, 
						],
					]
				}
			},
			
			// Line breaks
			'\n\n',
			
			{
				text:this.translate.instant('transaction_number') + ' : ' + invoice.transaction_number,
				style: 'notesText'
			},
			{
				text: this.translate.instant('pay_mod') + ' : ' + invoice.payment_mode,
				style:'notesText'
			},
			
			/* { 
				text:  this.translate.instant('pay_mod'),
				style:'notesTitle'
			},
			{ 
				text: invoice.payment_mode,
				style:'notesText'
			} */
			
			// Line breaks
			'\n\n',
			// Signature
			{
				columns: [
					{
						text:'',
					},
					{
						stack: [
							{ 
								text: '__________FIDELE MLM__________',
								style:'signaturePlaceholder'
							},
							{ 
								text: 'Mme Joséphine NGAMEGNI',
								style:'signatureName'
								
							},
							{ 
								text: 'Directrice Générale',
								style:'signatureJobTitle'
								
							}
							],
					   width: 180
					},
				]
			},
		],
	
		styles: {
		
			// Invoice Title
			invoiceTitle: {
			  fontSize: 22,
			  bold: true,
			  alignment:'right',
			  margin:[0,0,0,15]
			},
			
			// Invoice Details
			invoiceSubTitle: {
			  fontSize: 12,
			  alignment:'right'
			},
			invoiceSubValue: {
			  fontSize: 12,
			  alignment:'right'
			},
			
			// Billing Headers
			invoiceBillingTitle: {
			  fontSize: 14,
			  bold: true,
			  alignment:'left',
			  margin:[0,20,0,5],
			},
			
			// Billing Details
			invoiceBillingDetails: {
			  alignment:'left'

			},
			invoiceBillingAddressTitle: {
				margin: [0,7,0,3],
				bold: true
			},
			invoiceBillingAddress: {
				
			},
			
			// Items Header
			itemsHeader: {
				margin: [0,5,0,5],
				bold: true
			},
			
			// Item Title
			itemTitle: {
				bold: true,
			},
			itemSubTitle: {
					italics: true,
					fontSize: 11
			},
			itemTotal: {
				margin: [0,5,0,5],
				bold: true,
				alignment: 'center',
			},

			// Items Footer (Subtotal, Total, Tax, etc)
			itemsFooterSubTitle: {
				margin: [0,5,0,5],
				bold: true,
				alignment:'right',
			},
			itemsFooterSubValue: {
				margin: [0,5,0,5],
				bold: true,
				alignment:'center',
			},
			itemsFooterTotalTitle: {
				margin: [0,5,0,5],
				bold: true,
				alignment:'right',
			},
			itemsFooterTotalValue: {
				margin: [0,5,0,5],
				bold: true,
				alignment:'center',
			},
			signaturePlaceholder: {
				margin: [0,70,0,0],   
			},
			signatureName: {
				bold: true,
				alignment:'center',
			},
			signatureJobTitle: {
				italics: true,
				fontSize: 10,
				alignment:'center',
			},
			notesTitle: {
			  fontSize: 10,
			  bold: true,  
			  margin: [0,50,0,3],
			},
			notesText: {
			  fontSize: 10
			},
			center: {
				alignment:'center',
			},
		},
		
		defaultStyle: {
			columnGap: 20,
		}
	};
	
	return pdfMake.createPdf(this.documentDefinition).open();
	
  }

}
