import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { environment } from 'src/environments/environment';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-list-gains-all',
  templateUrl: './list-gains-all.component.html',
  styleUrls: ['./list-gains-all.component.css']
})
export class ListGainsAllComponent implements OnInit {

  gainsEarn: any = [];
  // formData = new FormData();
  baseUrl: string;
  urls: any;
  public error;
  public success;
  date: any;
  allLevel: any = [];

  public detailsData =
    {
      id: null,
      cash: null,
      natures: null,
      level_name: null,
      type: null,
      souscriptor_name: null,
      date_created: null,
    };

  baseurl: string;
  nature: any = "";

  constructor(private resourceService: ResourceService) { }

  ngOnInit() {
    this.getLevels();
    this.liste();
    this.baseurl = environment.baseUrl + '/public/';
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + '/public/files/avatars/avatar.png';
  }

  public liste() {

    this.resourceService.getResources('/list/gain/all').subscribe(data => {
      this.gainsEarn = data;
      console.log(data);
    }, error => {
      if(error.status==500 || error.status==503){
        console.log('erreur',error);
        //console.log(Errors(error));
      }
    });
  }

  public onView(gainEarn) {

    this.detailsData =
      {
        id: gainEarn.id,
        cash: gainEarn.cash,
        natures: gainEarn.nature,
        level_name: gainEarn.level_name,
        type: gainEarn.type,
        date_created: gainEarn.date_created,
        souscriptor_name: gainEarn.souscriptor_name
      };
  }

  getLevels(){
    this.resourceService.getResources('/level/all').subscribe(data => {
      this.allLevel = data;
    } ,err => {
      console.log('erreur : ', err);
    });
  }
}
